import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnItems } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import CreteItemModal from '../../../components/Modal/createItemModal'
import ViewItemsModal from '../../../components/Modal/viewItemsModal'
import { useQuery } from '@tanstack/react-query'
import { fetchItemsGlass } from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'

const Items = () => {
	const { t } = useTranslation()
	const formattedDate = getToday()

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isViewModalVisible, setIsViewModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [selectedViewRowData, setSelectedViewRowData] = useState(null)
	const [currentPage, setCurrentPage] = useState(0)
	const [itemName, setItemName] = useState('')
	const [itemCode, setItemCode] = useState('')

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['items', itemName, itemCode, currentPage],
		queryFn: fetchItemsGlass,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}
	const handleViewRowClick = (record) => {
		setSelectedViewRowData(record)
		setIsViewModalVisible(true)
	}

	const handleItemCodeChange = (val) => {
		debouncedSet(val, setItemCode)
	}

	const handleItemNameChange = (val) => {
		debouncedSet(val, setItemName)
	}

	const columns = columnItems(
		t,
		handleViewRowClick,
		handleItemCodeChange,
		handleItemNameChange,
	)

	return (
		<Layout>
			<Header
				title={'items'}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onBtnClick={handleRowClick}
				formattedDate={formattedDate}
				isSticky={false}
			/>
			<CreteItemModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsModalVisible(false)
					setCurrentPage(0)
					refetch()
				}}
			/>
			<ViewItemsModal
				visible={isViewModalVisible}
				data={selectedViewRowData}
				onClose={() => setIsViewModalVisible(false)}
			/>
		</Layout>
	)
}

export default Items
