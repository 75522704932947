import { Input } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { http } from '../../../services/http'
import SuccessModal from '../SuccessModal'
import ErrorModal from '../ErrorModal'
import { useQuery } from '@tanstack/react-query'

const BpInfo = ({ cardCode }) => {
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const [dataCardCode, setDataCardCode] = useState('')

	const fetchDownPayments = async ({ queryKey }) => {
		const [_, cardCode] = queryKey
		if (cardCode) {
			try {
				let url = `api/businesspartners/mijozlar?cardCode=${cardCode}`
				const { data } = await http.get(url)
				const formattedData = data[0]
				return formattedData
			} catch (error) {
				console.log(error)
			}
		}
	}

	const { data: bpData = [], isLoading } = useQuery({
		queryKey: ['salesPayments', dataCardCode],
		queryFn: fetchDownPayments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	if (!cardCode) return null

	useEffect(() => {
		setDataCardCode(cardCode)
	}, [cardCode])

	return (
		<div className="w-full px-4 ">
			<div className="w-full ">
				<div className="mt-5 mx-5 flex gap-10 items-start  drop-shadow-xl">
					<div className="flex flex-col items-start  justify-between gap-5  ">
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('Type')}:
							</span>
							<span className="text-lg">
								{bpData.type ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('Address')}:
							</span>
							<span className="text-lg">
								{bpData.address ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('email')}:
							</span>
							<span className="text-lg">
								{bpData.E_Mail ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('passportExpDate')}:
							</span>
							<span className="text-lg">
								{bpData.U_PassportExpDate ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('tgId')}:
							</span>
							<span className="text-lg">
								{bpData.u_TgId ?? '-'}
							</span>
						</div>
					</div>
					<div className=" flex flex-col items-start justify-between gap-5  ">
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('name')}:
							</span>
							<span className="text-lg">
								{bpData.cardName ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('phoneNum')}:
							</span>
							<span className="text-lg">
								{bpData.Cellular ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('passportSeries')}:
							</span>
							<span className="text-lg">
								{bpData.U_Passport ?? '-'}
							</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="font-bold text-lg">
								{t('passportGivenBy')}:
							</span>
							<span className="text-lg">
								{bpData.U_PassportGivenBy ?? '-'}
							</span>
						</div>
					</div>
				</div>
			</div>

			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</div>
	)
}

export default BpInfo
