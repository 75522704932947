import { Modal, Input, Table, Button, message, Select } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { http } from '../../services/http'
import SuccessModal from '../Modal/SuccessModal'
import ErrorModal from '../Modal/ErrorModal'

const ViewPurchasesModal = ({
	visible,
	data,
	onClose,
	ship = false,
	purchaseOrder = false,
	refetch,
}) => {
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const roleNames = useSelector((state) => state.main.roleNames)
	const employeeId = useSelector((state) => state.main.employeeId)
	const salesPersonCode = useSelector((state) => state.main.salesPersonCode)

	const [dueDate, setDueDate] = useState('')
	const [shipBtnLoading, setShipBtnLoading] = useState(false)
	const [status, setStatus] = useState('')
	const [tableData, setTableData] = useState([])

	useEffect(() => {
		if (data?.docDueDate) {
			setDueDate(data.docDueDate)
		}
		if (data?.u_Status) {
			setStatus(data.u_Status)
		}
		if (data?.documentLines) {
			setTableData(
				data.documentLines.map((item, index) => ({
					key: index,
					itemDescription: item.itemDescription,
					quantity: item.quantity,
					price: item.price,
					lineTotal: item.lineTotal,
					whsName: item.whsName,
					ugpCode: item.ugpCode,
					lineNum: item.lineNum,
					warehouseCode: item.warehouseCode,
					currency: item.currency,
					itemCode: item.itemCode,
				})),
			)
		}
	}, [data])

	if (!data) return null

	const shipData = async () => {
		setShipBtnLoading(true)

		const documentLines = tableData.map((item, idx) => {
			return {
				baseEntry: data.docEntry,
				baseLine: item.lineNum,
				itemCode: item.itemCode,
				quantity: item.quantity,
				price: item.price,
				currency: item.currency,
				warehouseCode: item.warehouseCode,
			}
		})

		const postingData = {
			cardCode: data?.cardCode,
			docDueDate: moment(data?.docDueDate, 'DD.MM.YYYY').format(
				'YYYY-MM-DD',
			),
			docDate: moment(data?.docDueDate, 'DD.MM.YYYY').format(
				'YYYY-MM-DD',
			),
			documentsOwner: employeeId,
			salesPersonCode: salesPersonCode,
			docCurrency: data.docCurrency,
			comments: data?.comments || '',
			u_FuraNum: data.u_FuraNum || '',
			u_InvoiceNum: data.docNum || '',
			documentLines,
		}

		try {
			await http.post(
				'api/purchase/yetib-kelgan-buyurtmalar',
				postingData,
			)
			onClose()
			refetch()
			successRef.current?.open(t('sucCreated'))
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		} finally {
			setShipBtnLoading(false)
		}
	}
	const patchStatus = async () => {
		setShipBtnLoading(true)

		try {
			await http.patch(
				'api/purchase/xarid-uchun-buyurtma/update-status',
				{
					docNum: data.docNum,
					docEntry: data.docEntry,
					u_Status: status,
				},
			)
			successRef.current?.open(t('sucChangeStatus'))
			onClose()
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		} finally {
			setShipBtnLoading(false)
		}
	}

	const handleQuantityChange = (value, index) => {
		const updatedData = [...tableData]
		updatedData[index].quantity = value
		setTableData(updatedData)
	}

	const columns = [
		{
			title: t('item'),
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			width: 150,
			render: (text, record, index) => {
				return <span>{text}</span>
				// className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}
			},
		},
		{
			title: t('quantityN'),
			dataIndex: 'quantity',
			key: 'quantity',
			render: (text, record, index) =>
				ship && data.u_Status === 'Arrived' ? (
					<Input
						type="number"
						value={record.quantity}
						onChange={(e) =>
							handleQuantityChange(Number(e.target.value), index)
						}
					/>
				) : (
					<span> {new Intl.NumberFormat('fr-FR').format(text)}</span>
				),
		},
		{
			title: t('kv.m'),
			dataIndex: 'ugpCode',
			key: 'ugpCode',
		},

		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span>{formattedText} USD</span>
			},
		},

		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					record.quantity * record.price,
				)
				return <span>{formattedText} USD</span>
			},
		},
	]

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('order')} № {data.docNum}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t('mijoz')}</p>
							<Input
								type="text"
								value={data.cardName}
								className="flex  w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						{data.u_Status ? (
							<div className="flex flex-col gap-3">
								<p className="font-bold">{t('status')}</p>
								<Select
									type="text"
									value={t(`${status}`)}
									className="flex  w-[200px] items-center justify-center sm:w-[250px]"
									style={{ color: 'black' }}
									disabled={!purchaseOrder}
									onChange={(val) => {
										setStatus(val)
									}}
								>
									<Select.Option value={'Not Arrived'}>
										{t('NotArrived')}
									</Select.Option>
									<Select.Option value={'Arrived'}>
										{t('Arrived')}
									</Select.Option>
								</Select>
							</div>
						) : (
							''
						)}

						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('currency')}</p>
							<Input
								value={data.documentLines[0].currency}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('date')}</p>
							<Input
								type="text"
								value={dueDate}
								style={{ color: 'black' }}
								onChange={(date) => setDueDate(date)}
								disabled
							/>
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: '100%' }}
						bordered
					/>

					<div className="w-full flex items-center justify-between mt-20  ">
						<div className="flex items-center gap-3 ">
							<p className="font-bold">
								{t('totalForDocument')}:
							</p>
							<Input
								type="text"
								value={`${new Intl.NumberFormat('fr-FR').format(
									tableData?.reduce(
										(total, item) =>
											total + item.price * item.quantity,
										0,
									),
								)} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						<div>
							{ship && data.u_Status === 'Arrived' ? (
								<Button
									onClick={shipData}
									loading={shipBtnLoading}
									className="h-[30px] w-auto  bg-[#0A4D68] rounded-lg text-white "
								>
									{t('ship')}
								</Button>
							) : (
								''
							)}

							{purchaseOrder ? (
								<Button
									onClick={patchStatus}
									loading={shipBtnLoading}
									className="h-[30px] w-auto bg-[#0A4D68] rounded-lg text-white "
								>
									{t('changeStatus')}
								</Button>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default ViewPurchasesModal
