import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnBranches } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import { useQuery } from '@tanstack/react-query'
import { http } from '../../../services/http'
import EditBranchModal from '../../../components/Modal/editBranchModal.jsx'
import CreateBranchModal from '../../../components/Modal/createBranchModal'

const Branches = () => {
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [selectedViewRowData, setSelectedViewRowData] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)

  const fetchBranches = async () => {
    try {
      let url = `api/departments`
      const { data } = await http.get(url)
      return data.filter((branches) => {
        return Number(branches.code) > 0
      })
    } catch (error) {
      console.log(error)
    }
  }

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['branches'],
    queryFn: fetchBranches,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }

  const handleViewRowClick = (record) => {
    setSelectedViewRowData(record)
    setIsViewModalVisible(true)
  }

  const columns = columnBranches(t, handleViewRowClick)

  return (
    <Layout>
      <Header
        title={'branches'}
        currentPage={currentPage + 1}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        onBtnClick={handleRowClick}
        isSticky={false}
      />
      <CreateBranchModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          setIsModalVisible(false)
          setCurrentPage(0)
          refetch()
        }}
      />
      <EditBranchModal
        visible={isViewModalVisible}
        data={selectedViewRowData}
        onClose={() => {
          setIsViewModalVisible(false)
          refetch()
        }}
      />
    </Layout>
  )
}

export default Branches
