import React, { useEffect, useState } from 'react'
import BarcodeGenerator from '../../../components/Barcode/index'
import './main.css'

const AdminSeriesBarcodePage = () => {
	const [data, setData] = useState([])

	useEffect(() => {
		setTimeout(() => {
			window.print()
		}, 1000)
	}, [])

	useEffect(() => {
		const storedData = localStorage.getItem('adminSeriesData')
		console.log('storedData', storedData)
		if (storedData) {
			setData(JSON.parse(storedData))
			console.log('data = ', JSON.parse(storedData))
		}
	}, [])

	return (
		<div className="p-2 bg-white text-black font-sans w-[350px]">
			{data.map((item) => (
				<div
					key={`${item.docNum}-${item.serialNumber}`}
					className="page-break"
				>
					<div className="flex flex-col gap-1 mt-3">
						<p className="text-sm font-extrabold text-[#000000]">
							{item.creatorName}
						</p>
						<div className="flex gap-5 items-center justify-between">
							<p className="font-nunito text-sm font-extrabold text-[#000000]">
								{item.docNum}
							</p>
							<div className="flex gap-1">
								<p>Дата:</p>
								<p className="font-nunito font-extrabold text-[#000000]">
									{item.dueDate}
								</p>
							</div>
						</div>
						<p className="text-[12px]">{item.itemName}</p>
						<div className="flex items-center gap-5 justify-between">
							<p className="text-sm font-extrabold">
								{`${item.width ? `${item.width} x ${item.hight}` : ''} `}
							</p>
							<div className="flex items-center gap-1">
								<p>Деталь:</p>
								<p className="text-sm font-extrabold">
									{item.serialNumber}
								</p>
							</div>
						</div>
					</div>
					<BarcodeGenerator
						docNum={item.serialNumber}
						displayValue={false}
						width={2}
						height={40}
						margin={0}
						fontSize={'10'}
						className="mt-3 text-left"
					/>
				</div>
			))}
		</div>
	)
}

export default AdminSeriesBarcodePage
