import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnEmployees } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../../utils/debounce'
import { http } from '../../../services/http'
import EditEmployeesModal from '../../../components/Modal/editEmployeesModal'
import { fetchDepartments, fetchRoles } from '../../../utils/fetchData'

const Employees = () => {
	const { t } = useTranslation()
	const formattedDate = getToday()

	const [isViewModalVisible, setIsViewModalVisible] = useState(false)
	const [selectedViewRowData, setSelectedViewRowData] = useState(null)
	const [currentPage, setCurrentPage] = useState(0)
	const [name, setName] = useState('')
	const [role, setRole] = useState('')
	const [department, setDepartment] = useState('')

	const fetchData = async ({ queryKey }) => {
		const [_, name, role, department, page] = queryKey
		try {
			let url = `api/users?name=${name}&department=${department}&role=${role}&pageSize=20&skip=${page}`
			const { data } = await http.get(url)
			return data
		} catch (error) {
			console.log(error)
		}
	}

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['employees', name, role, department, currentPage],
		queryFn: fetchData,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: departmentData = [] } = useQuery({
		queryKey: ['departments'],
		queryFn: fetchDepartments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: rolesData = [] } = useQuery({
		queryKey: ['roles'],
		queryFn: fetchRoles,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 20) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleViewRowClick = (record) => {
		setSelectedViewRowData(record)
		setIsViewModalVisible(true)
	}

	const handleNameChange = (val) => {
		debouncedSet(val, setName)
	}

	const handleRoleChange = (val) => {
		debouncedSet(val, setRole)
	}

	const handleDepartmentChange = (val) => {
		debouncedSet(val, setDepartment)
	}

	const columns = columnEmployees(
		t,
		handleViewRowClick,
		handleNameChange,
		handleRoleChange,
		handleDepartmentChange,
		departmentData,
		rolesData,
	)

	return (
		<Layout>
			<Header
				title={'employees'}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 20}
				columns={columns}
				fdata={data}
				loading={isLoading}
				formattedDate={formattedDate}
				isSticky={false}
				scrollX={1300}
			/>

			<EditEmployeesModal
				visible={isViewModalVisible}
				data={selectedViewRowData}
				onClose={() => {
					setIsViewModalVisible(false)
					refetch()
				}}
			/>
		</Layout>
	)
}

export default Employees
