import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnPurchaseOrder } from '../../../utils/columns'
import ViewPurchaseIncomingModal from '../../../components/Modal/viewPurchaseIncomingModal'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout/index'
import { useSelector } from 'react-redux'
import { debouncedSet } from '../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import { fetchPurchasesWithPageSize } from '../../../utils/fetchData'

const PurchaseIncoming = () => {
	const { t } = useTranslation()

	const roleNames = useSelector((state) => state.main.roleNames)
	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [cardName, setCardName] = useState('')
	const [slpName, setSlpName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'purchaseIncoming',
			currentPage,
			cardName,
			docNum,
			startDate,
			endDate,
			slpName,
			'api/purchase/yetib-kelgan-buyurtmalar',
		],
		queryFn: fetchPurchasesWithPageSize,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName)
	}

	const columns = columnPurchaseOrder(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange,
		roleNames.includes('Supplier') ? true : false,
	)
	return (
		<Layout>
			<Header
				title={'purchase-incoming'}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
				isSticky={false}
			/>
			<ViewPurchaseIncomingModal
				visible={isModalVisible}
				data={selectedRowData}
				{...(roleNames.includes('Supplier')
					? { finishSale: true }
					: {})}
				onClose={() => {
					setIsModalVisible(false)
					refetch()
				}}
				refetch={refetch}
			/>
		</Layout>
	)
}

export default PurchaseIncoming
