import { Modal, Input, Table, Button, message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import OrderPaymentModal from './orderPaymentModal'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { fetchSeeSalesView } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'

const ViewSeeSalesModal = ({
	visible,
	data,
	onClose,
	onClick,
	finish = false,
	incoming = false,
	refetch,
}) => {
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [dueDate, setDueDate] = useState('')
	const [deadLine, setDeadline] = useState('')
	const [path, setPath] = useState([])
	const [docEntry, setDocEntry] = useState('')

	const {
		data: viewData = [],
		isLoading,
		refetch: viewSalesRefetch,
	} = useQuery({
		queryKey: ['seeSalesView', docEntry],
		queryFn: fetchSeeSalesView,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		console.log('Datas', data)
		if (data?.docEntry) {
			setDocEntry(data?.docEntry)
		}
	}, [data])

	useEffect(() => {
		if (viewData?.docDate) {
			setDueDate(moment(viewData[0]?.docDate).format('DD.MM.YYYY'))
			setDeadline(moment(viewData[0]?.docDueDate).format('DD.MM.YYYY'))
		}
		if (viewData[0]?.u_ChertejId) {
			const photoId = viewData?.u_ChertejId
			const photoPaths = photoId
				? photoId.includes(',')
					? photoId.split(',')
					: [photoId]
				: []
			const initialPath = photoPaths.map((path) => ({
				file: 'not empty',
				has: true,
				path,
				imgLoading: false,
			}))
			const finalPath =
				initialPath.length > 0
					? initialPath
					: [{ file: null, path: '', imgLoading: false }]
			setPath(finalPath)
		}
	}, [viewData])

	const viewImage = async (index) => {
		const image = path[index]

		try {
			const res = await http.get(`api/assets/download/${image.path}`, {
				responseType: 'blob',
			})

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error(t('errorDownload'))
		}
	}

	if (!data) return null
	if (!viewData) return null

	const columns = [
		{
			title: t('item'),
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			width: 150,
			render: (text, record, index) => {
				return <span>{text}</span>
			},
		},

		{
			title: t('kv.m'),
			dataIndex: 'quantity',
			key: 'quantity',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},
		{
			title: t('openKvm'),
			dataIndex: 'openQty',
			key: 'openQty',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},

		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},

		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},
	]

	const tableData = viewData[0]?.documentLines
		?.filter((item) => {
			return item.u_Produkt === null
		})
		.map((item, index) => ({
			key: index,
			itemDescription: item.itemDescription,
			quantity: item.quantity,
			price: item.price,
			lineTotal: item.lineTotal,
			whsName: item.whsName,
			u_Produkt: item.u_Produkt,
			currency: item.currency,
			openQty: item.openQty,
		}))

	const columnsServices = [
		{
			title: t('service'),
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			width: 150,
			render: (text, record) => {
				return (
					<span>
						<span className="font-bold">
							{record.u_Number ? `${record.u_Number} ` : ''}
						</span>
						{text}
					</span>
				)
			},
		},

		{
			title: t('kv.m'),
			dataIndex: 'quantity',
			key: 'quantity',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},

		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},

		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},
		{
			title: t('u_Produkt'),
			dataIndex: 'u_Produkt',
			key: 'u_Produkt',
		},
	]

	const tableDataServices = viewData[0]?.documentLines
		.filter((item) => {
			return item.u_Produkt !== null
		})
		.map((item, index) => ({
			key: index,
			itemDescription: item.itemDescription,
			quantity: item.quantity,
			price: item.price,
			lineTotal: item.lineTotal,
			whsName: item.whsName,
			u_Produkt: item.u_Produkt,
			currency: item.currency,
			openQty: item.openQty,
			u_Number: item?.u_Number,
		}))

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('order')} № {data.docNum}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t('mijoz')}</p>
							<Input
								type="text"
								value={viewData[0]?.cardName}
								className="flex w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('status')}</p>
							<Input
								value={viewData[0]?.u_AbsoluteStatus}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('currency')}</p>
							<Input
								value={viewData[0]?.docCurrency}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('date')}</p>
							<Input
								type="text"
								value={dueDate}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('deadLine2')}</p>
							<Input
								type="text"
								value={deadLine}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
					</div>

					<div className="mt-12">
						<p className="font-bold text-center">{t('items')}</p>
						<Table
							columns={columns}
							dataSource={tableData}
							pagination={false}
							className="border-1 mt-5  border-black"
							scroll={{ x: '100%' }}
							bordered
							loading={isLoading}
						/>
					</div>

					<div className="mt-12">
						<p className="font-bold text-center">{t('services')}</p>
						<Table
							columns={columnsServices}
							dataSource={tableDataServices}
							pagination={false}
							className="border-1 mt-5 border-black"
							scroll={{ x: '100%' }}
							bordered
						/>
					</div>

					<div className="flex w-full items-center gap-5 mt-5">
						{path.map((image, index) =>
							image.has
								? image.path && (
										<div
											key={index}
											className="flex w-full items-start flex-col gap-3 "
										>
											<div>
												<p className="font-bold">
													{t('photo')}-{index + 1}
												</p>
											</div>
											<div className="flex items-center gap-3">
												<Button
													onClick={() =>
														viewImage(index)
													}
													className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
												>
													{t('download')}{' '}
												</Button>
											</div>
										</div>
									)
								: '',
						)}
					</div>

					<div className="w-full flex items-center justify-between mt-5  ">
						<div className="flex items-center gap-3 ">
							<p className="font-bold">
								{t('totalForDocument')}:
							</p>
							<Input
								type="text"
								value={`${new Intl.NumberFormat('fr-FR').format(viewData[0]?.docTotal)} ${viewData[0]?.docCurrency}`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						{/* <div>
              {roleNames.includes('Cashier') ? (
                <Button
                  onClick={() => {
                    setIsModalVisible(true)
                  }}
                  className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
                >
                  {t('pay')}
                </Button>
              ) : (
                ''
              )}
              {roleNames.includes('Wrhmanager') ? (
                <Button
                  onClick={shipData}
                  loading={shipBtnLoading}
                  className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
                >
                  {t('ship')}
                </Button>
              ) : (
                ''
              )}
            </div> */}
					</div>
				</div>
				<OrderPaymentModal
					visible={isModalVisible}
					data={data}
					onClose={() => setIsModalVisible(false)}
				/>
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</div>
		</Modal>
	)
}

export default ViewSeeSalesModal
