import * as React from 'react'
const AccountsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7 14v3M11 12v5M15 14v3M19 12v5M2 2v16a4 4 0 0 0 4 4h16"
    />
    <path
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7 10 2.586-2.586a2 2 0 0 1 2.828 0l1.172 1.172a2 2 0 0 0 2.828 0L19 6"
    />
  </svg>
)
export default AccountsIcon
