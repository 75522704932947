import { Modal, Input, Table, Button, message, Select } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { fetchPriorities } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import { http } from '../../services/http'
const ViewProductionOrderModal = ({
	visible,
	data,
	onClose,
	refetch,
	isWorker = false,
}) => {
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const [priorityBtn, setPriorityBtn] = useState(false)
	const [priority, setPriority] = useState('')
	const [edit, setEdit] = useState(false)

	useEffect(() => {
		if (data?.priority) {
			setPriority(data.priority)
		}
	}, [data])

	const patchData = async () => {
		setPriorityBtn(true)
		const documentLines = data?.documentLines.map((item, index) => ({
			lineId: item.lineId,
			startTime: item.startTime,
			endTime: item.endTime,
			wastedTime: item.wastedTime,
			isStarted: item.isStarted,
			isReady: item.isReady,
			isReadyForNextStage: item.isReadyForNextStage,
		}))
		const patchingData = {
			priority,
			status: data.status,
			docEntry: data?.docEntry,
			documentLines,
		}
		try {
			await http.patch(
				`api/pots/update-pots/${data?.docEntry}`,
				patchingData,
			)
			closeModal()
			if (refetch) refetch()
			successRef.current?.open(t('sucChanged'))
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		} finally {
			setPriorityBtn(false)
		}
	}

	const { data: prioritiesData = [] } = useQuery({
		queryKey: ['priorities'],
		queryFn: fetchPriorities,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	if (!data) return null

	const columns = [
		{
			title: t('service'),
			dataIndex: 'description',
			key: 'description',
			width: 150,
			render: (text, record) => {
				console.log('record =', record)
				return (
					<span>
						<span className="font-bold">
							{record.number ? `${record.number} ` : ''}
						</span>
						{text}
					</span>
				)
			},
		},
		{
			title: t('serialNumber'),
			dataIndex: 'serialNumber',
			key: 'serialNumber',
		},

		{
			title: t('startTime'),
			dataIndex: 'startTime',
			key: 'startTime',
		},

		{
			title: t('endTime'),
			dataIndex: 'endTime',
			key: 'endTime',
		},

		{
			title: t('wastedTime'),
			dataIndex: 'wastedTime',
			key: 'wastedTime',
		},
	]

	const closeModal = () => {
		onClose()
		setEdit(false)
		setPriorityBtn(false)
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('order')} № {data?.docNum}
				</h1>
			}
			open={visible}
			onCancel={closeModal}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-start justify-between sm:flex-row">
						<div className="flex flex-col gap-3 sm:w-[250px]">
							<p className="font-bold">{t('priority')}</p>
							<Select
								type="text"
								value={priority}
								className="flex  w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: 'black' }}
								onChange={(val) => {
									setPriority(val)
								}}
								disabled={priorityBtn || !edit}
								options={prioritiesData?.map((whs) => ({
									label: whs.descr,
									value: whs.fldValue,
								}))}
							/>
							{priority === data?.priority &&
							edit &&
							!isWorker ? (
								<span className="text-red-600 text-ellipsis text-center">
									{t('changePriorityAndClickSave')}
								</span>
							) : null}
						</div>
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t('status')}</p>
							<Input
								value={data.status}
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						<div className="flex flex-col gap-3">
							<p className="font-bold">{t('item')}</p>
							<Input
								value={data.itemName}
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('dueDate')}</p>
							<Input
								type="text"
								value={data.dueDate}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={data?.documentLines}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: '100%' }}
						bordered
					/>

					{!isWorker && (
						<div className="w-full flex flex-col sm:flex-row items-center justify-end gap-5 mt-20  ">
							<Button
								onClick={() => setEdit(!edit)}
								disabled={priorityBtn || edit}
								className="h-[30px]   bg-[#0A4D68] rounded-lg text-white "
							>
								{t('changePriority')}
							</Button>
							<Button
								onClick={patchData}
								loading={priorityBtn}
								disabled={priorityBtn || !edit}
								className="h-[30px]   bg-[#0A4D68] rounded-lg text-white "
							>
								{t('save')}
							</Button>
						</div>
					)}
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default ViewProductionOrderModal
