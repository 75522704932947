import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, Select, AutoComplete } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../../../../services/http'
import { useSelector } from 'react-redux'
import SuccessModal from '../../../../../components/Modal/SuccessModal'
import ErrorModal from '../../../../../components/Modal/ErrorModal'
import { useQuery } from '@tanstack/react-query'
import { get } from 'lodash'
import { debouncedSet } from '../../../../../utils/debounce'
import {
  fetchDdsArticle,
  fetchSuppliersSelect,
} from '../../../../../utils/fetchData'
import moment from 'moment'

const PaySupplierModal = ({ visible, onClose }) => {
  const { t } = useTranslation()
  const successRef = useRef()
  const errorRef = useRef()

  const uzsCash = useSelector((state) => state.main.uzsCash)
  const usdCash = useSelector((state) => state.main.usdCash)

  const [loading, setLoading] = useState(false)
  const [dds, setDds] = useState('')
  const [currency, setCurrency] = useState('')
  const [comment, setComment] = useState('')
  const [amount, setAmount] = useState('')
  const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))
  const [clientCode, setClientCode] = useState('')
  const [clientName, setClientName] = useState('')
  const [searchClientName, setSearchClientName] = useState('')

  const postData = async () => {
    setLoading(true)
    try {
      await http.post('api/vendorpayments/suppliers', {
        cardCode: clientCode,
        cashAccount:
          currency === 'USD' ? usdCash : currency === 'UZS' ? uzsCash : '',
        docCurrency: currency,
        cashSum: Number(amount),
        remarks: comment,
        u_DdsArticle: dds,
        docDate,
      })
      resetForm()
      onClose()

      successRef.current.open(t('sucCreated'))
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    setDds('')
    setCurrency('')
    setComment('')
    setAmount('')
    setDocDate(moment().format('YYYY-MM-DD'))
    setClientCode('')
    setClientName('')
    setSearchClientName('')
  }

  const { data: ddsData = [], isLoading: ddsLoading } = useQuery({
    queryKey: ['dds-articles'],
    queryFn: fetchDdsArticle,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const {
    data: clientsData = [],
    isLoading: loadingClient,
    refetch,
  } = useQuery({
    queryKey: [
      'clients',
      searchClientName,
      '20',
      'api/businesspartners/yetkazib-beruvchilar',
    ],
    queryFn: fetchSuppliersSelect,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleCardNameChange = (val) => {
    debouncedSet(val, setSearchClientName)
  }

  return (
    <Modal
      title={
        <h1 className="font-nunito  font-extrabold text-[#000000]">
          {t('paySupplier')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="65%"
      style={{ height: '70%' }}
    >
      <div className="w-full px-2 sm:px-14">
        <div className="flex items-center justify-between mt-5">
          <div className=" flex flex-col items-start gap-3  ">
            <p className="font-bold">{t('Client')}</p>
            <AutoComplete
              loading={loadingClient}
              showSearch
              value={clientName}
              className="flex-1 h-12 w-[350px]"
              onSelect={(value) => {
                const val = value.split(' ,/ ')[0]

                setClientName(val)
                handleCardNameChange(val)
                const selectedOption = clientsData.find((client) => {
                  const cardName = get(client, 'cardName', '')
                  const cardCode = get(client, 'cardCode', '')
                  const phoneNumber = get(client, 'Cellular', '')
                    ? get(client, 'Cellular', '')
                    : ''
                  const formattedValue = [cardName, phoneNumber]
                    .filter(Boolean)
                    .join(' ')
                  return `${formattedValue} ,/ ${cardCode}` === value
                })

                if (selectedOption) {
                  const cardCode = get(selectedOption, 'cardCode', '')
                  setClientCode(cardCode)
                }
              }}
              onChange={(val) => {
                const value = val.split(' ,/ ')[0]
                setClientName(value)
                handleCardNameChange(value)
              }}
              options={clientsData.map((client) => ({
                value: `${client.cardName}${client.Cellular ? ` ${client.Cellular}` : ''} ,/ ${client.cardCode}`,
                label: `${client.cardName} ${client.Cellular ? client.Cellular : ''}`,
              }))}
            />
          </div>

          <div className="flex flex-col gap-3 ">
            <p className="font-bold">{t('currency')}</p>

            <Select
              type="text"
              onChange={(val) => {
                setCurrency(val)
              }}
              value={currency}
              className="flex h-10 w-[250px] items-center justify-center "
            >
              <Select.Option value={'USD'}>USD</Select.Option>
              <Select.Option value={'UZS'}>UZS</Select.Option>
            </Select>
          </div>
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className=" flex flex-col items-start gap-3  ">
            <p className="font-bold">{t('dds')}</p>
            <Select
              type="text"
              onChange={(val) => {
                setDds(val)
              }}
              value={dds}
              loading={ddsLoading}
              className="flex h-10 w-[350px] items-center justify-center"
            >
              {ddsData.map((dds, idx) => (
                <Select.Option key={idx} value={dds.fldValue}>
                  {dds.descr}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-col items-start gap-3 ">
            <p className="font-bold">{t('payingAmount')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setAmount(e.target.value)
              }}
              value={amount}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full mt-5">
          <p className="font-bold">{t('comments')}</p>
          <TextArea
            onChange={(e) => {
              setComment(e.target.value)
            }}
            value={comment}
            className="w-full"
            rows={4}
          />
        </div>

        <div className="flex items-center gap-10 mt-14 justify-between">
          <Button
            onClick={() => {
              onClose()
            }}
            className="w-full bg-red-600 h-[35px] rounded-lg text-white"
          >
            {t('back')}
          </Button>
          <Button
            onClick={postData}
            loading={loading}
            disabled={loading}
            className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
          >
            {t('add')}
          </Button>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default PaySupplierModal
