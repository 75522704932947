import React, { useState, useRef, useEffect } from 'react'
import { Modal, Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'

const EditBranchModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation()
  const successRef = useRef()
  const errorRef = useRef()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [code, setCode] = useState('')

  const patchData = async () => {
    setLoading(true)

    try {
      await http.patch(`api/departments/${code}`, {
        name: data?.name,
        description: name,
      })
      successRef.current.open(t('sucEdited'))
      resetForm()
      onClose()
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setCode(data?.code)
    setName(data?.description)
  }, [data])

  const resetForm = () => {
    setCode('')
    setName('')
  }

  if (!data) return null

  return (
    <Modal
      title={
        <h1 className="font-nunito  font-extrabold text-[#000000]">
          {t('editBranch')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="50%"
      style={{ height: '70%' }}
    >
      <div className="w-full px-2 sm:px-14">
        <div className="flex items-center gap-5 justify-between mt-4">
          <div className=" flex flex-col gap-3 sm:mt-5 w-full">
            <p>{`${t('code')}`}</p>
            <Input
              type="text"
              value={code}
              disabled
              className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
          <div className=" flex flex-col gap-3 sm:mt-5 w-full">
            <p>{`${t('name')}`}</p>
            <Input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>

        <div className="flex items-center gap-10 mt-14 justify-between">
          <Button
            onClick={() => {
              onClose()
            }}
            className="w-full bg-red-600 h-[35px] rounded-lg text-white"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={patchData}
            loading={loading}
            disabled={loading}
            className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
          >
            {t('save')}
          </Button>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default EditBranchModal
