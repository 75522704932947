import { Modal, Table, Input, message, Button, Select } from 'antd'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import { useSelector } from 'react-redux'
import moment from 'moment'
import SuccessModal from '../Modal/SuccessModal'
import ErrorModal from '../Modal/ErrorModal'
import { fetchCostTypes } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'

const CreteLandedCostsModal = ({
	visible,
	data,
	docDate,
	onClose2,
	refetch,
}) => {
	const { t } = useTranslation()
	const successRef = useRef()
	const errorRef = useRef()

	const employeeId = useSelector((state) => state.main.employeeId)
	const salesPersonCode = useSelector((state) => state.main.salesPersonCode)

	const [loading, setLoading] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [currency, setCurrency] = useState('')
	const [landedCosts, setLandedCosts] = useState([
		{
			landedCostCode: '',
			landedCostItem: '',
			price: '',
		},
	])

	const { data: costTypes = [], isLoading: costTypesLoading } = useQuery({
		queryKey: ['landed-costs'],
		queryFn: fetchCostTypes,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})
	const postData = async (docEntry) => {
		setLoading(true)

		try {
			const landedCostItemLines = data?.documentLines.map((item, idx) => {
				return {
					baseEntry: docEntry,
					baseLine: item.lineNum,
				}
			})
			const landedCostCostLines = landedCosts.map((item, idx) => {
				return {
					landedCostCode: item.landedCostCode,
					amount: Number(item.price),
				}
			})

			const postingData = {
				vendorCode: data?.cardCode,
				documentCurrency: currency,
				landedCostItemLines,
				landedCostCostLines,
			}
			await http.post('api/landedcosts', postingData)
			successRef.current?.open(t('sucCreation'))
			refetch()
			onClose2()
			resetForm()
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	const resetForm = () => {
		setCurrency('')

		setLandedCosts([
			{
				landedCostCode: '',
				landedCostItem: '',
				price: '',
			},
		])
	}

	if (!data) return null

	const removeRow = (key) => {
		if (landedCosts.length > 1) {
			setLandedCosts(landedCosts.filter((row) => row.key !== key))
		}
	}

	const addRow = () => {
		const newRow = {
			key: Date.now(),
			landedCostItem: '',
			price: '',
			landedCostCode: '',
		}
		setLandedCosts([...landedCosts, newRow])
	}

	const columns = [
		{
			title: t('landedCost'),
			dataIndex: 'item',
			key: 'item',
			width: 300,
			render: (_, record) => (
				<Select
					value={record.landedCostCode}
					loading={costTypesLoading}
					options={costTypes}
					className="w-full"
					key="alcCode"
					fieldNames={{ label: 'alcName', value: 'alcCode' }}
					onChange={(val, option) => {
						console.log(option)
						const updatedData = landedCosts.map((row) =>
							row.key === record.key
								? {
										...row,
										landedCostCode: val,
										landedCostItem: option.alcName,
									}
								: row,
						)
						setLandedCosts(updatedData)
					}}
					showSearch
					filterOption={(input, option) =>
						option.alcName
							.toLowerCase()
							.includes(input.toLowerCase())
					}
				/>
			),
		},

		{
			title: t('priceSum'),
			dataIndex: 'price',
			key: 'price',
			width: 150,
			render: (_, record) => (
				<Input
					value={record.price}
					className="w-full"
					type="text"
					onChange={(e) => {
						const updatedData = landedCosts.map((row) =>
							row.key === record.key
								? {
										...row,
										price: e.target.value,
									}
								: row,
						)
						setLandedCosts(updatedData)
					}}
				/>
			),
		},

		{
			title: t('actions'),
			key: 'actions',
			render: (_, record) => (
				<div className="flex gap-2">
					<Button type="primary" onClick={addRow}>
						+
					</Button>
					<Button
						danger
						type="primary"
						onClick={() => removeRow(record.key)}
						disabled={landedCosts.length === 1}
					>
						-
					</Button>
				</div>
			),
		},
	]

	const finishSalePost = async () => {
		setLoading(true)

		const documentLines = data?.documentLines.map((item, idx) => {
			return {
				baseEntry: data.docEntry,
				baseLine: item.lineNum,
				itemCode: item.itemCode,
				quantity: item.quantity,
				price: item.price,
				currency: item.currency,
				warehouseCode: item.warehouseCode,
			}
		})

		const postingData = {
			cardCode: data?.cardCode,
			documentsOwner: employeeId,
			salesPersonCode: salesPersonCode,
			docDueDate: moment(docDate).format('YYYY-MM-DD'),
			docDate: moment(docDate).format('YYYY-MM-DD'),
			comments: '',
			docCurrency: data.documentLines[0].currency,
			u_FuraNum: data.u_FuraNum || '',
			u_InvoiceNum: data.docNum || '',
			documentLines,
		}
		try {
			await http
				.post('api/purchase/haridni-yakunlash', postingData)
				.then((res) => {
					postData(res.data?.docEntry)
					console.log(res)
				})
		} catch (error) {
			message.error(error?.response?.data?.message)
			onClose2()
		} finally {
			setLoading(false)
		}
	}

	const handleButtonClick = () => {
		setIsModalVisible(true)
	}

	const handleModalYes = () => {
		finishSalePost()
		setIsModalVisible(false)
	}

	const handleModalNo = () => {
		setIsModalVisible(false)
	}

	const handleModalCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('addLandedCost')}
				</h1>
			}
			open={visible}
			onCancel={onClose2}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="50%"
			style={{ height: '70%' }}
		>
			<div className="flex flex-col gap-2 mt-5">
				<p>{t('currency')}</p>
				<Select
					value={currency}
					onChange={(val) => {
						setCurrency(val)
					}}
					className="h-[30px] w-[200px]  rounded-lg text-white"
					options={[
						{
							label: 'UZS',
							value: 'UZS',
						},
						{
							label: 'USD',
							value: 'USD',
						},
					]}
				/>
			</div>
			<Table
				columns={columns}
				dataSource={landedCosts}
				pagination={false}
				className="border-1 mt-12 border-black"
				bordered
				scroll={{ x: 'max-content' }}
			/>
			<div className="flex items-center justify-end mt-8 mb-2">
				{' '}
				<Button
					onClick={handleButtonClick}
					loading={loading}
					className="h-[30px] bg-[#0A4D68] rounded-lg text-white"
				>
					{t('save')}
				</Button>
			</div>
			<Modal
				title={
					<h1 className="font-nunito  font-extrabold text-[#000000]">
						{t('isThisCorrect')}
					</h1>
				}
				open={isModalVisible}
				onCancel={handleModalCancel}
				footer={null}
			>
				<div>
					{landedCosts.map((item) => (
						<div>
							<p className="m-4">
								<span className="font-bold">
									{item.landedCostItem}
								</span>{' '}
								---{' '}
								<span className={'text-blue-700'}>
									{item.price} {currency}
								</span>
							</p>
						</div>
					))}
				</div>
				<div className="flex items-center gap-5 mt-5 justify-between">
					<Button
						className="bg-red-600 text-white"
						key="no"
						onClick={handleModalNo}
					>
						{t('no')}
					</Button>

					<Button
						key="yes"
						className="bg-[#0A4D68] text-white"
						onClick={handleModalYes}
					>
						{t('yes')}
					</Button>
				</div>
			</Modal>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
				onConfirm={() => {
					refetch()
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default CreteLandedCostsModal
