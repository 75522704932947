import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table, Input } from 'antd'
import ViewProductionWorkersModal from '../../viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../SuccessModalWithTimer/index.jsx'
import ErrorModalWithTimer from '../../ErrorModalWithTimer/index.jsx'
import { columnProductionInSalesModal } from '../../../../utils/columns.jsx'
import { fetchProductionInSalesModal } from '../../../../utils/fetchData.js'
import moment from 'moment'
import { http } from '../../../../services/http.js'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import './table.css'
import { isAfter, isBefore, isSameDay } from '../../../../utils/time'
import debounce from 'lodash.debounce'

const ProductionInViewSales = ({ docEntry }) => {
	const { t } = useTranslation()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const successRef = useRef()
	const errorRef = useRef()
	const tableRef = useRef(null)

	const formattedDate = moment()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [pageSize] = useState('10')
	const [isReadyForNextStage] = useState('')
	const [isStarted] = useState('')
	const [isReady] = useState('')
	const [isFetching, setIsFetching] = useState(false)
	const [productionWorkersData, setProductionWorkersData] = useState([])

	const meaningOfBg = [
		{
			id: 1,
			className: 'bg-green-400',
			meaning: t('done'),
		},
		{
			id: 2,
			className: 'bg-blue-400',
			meaning: t('inProcess2'),
		},
		{
			id: 3,
			className: 'bg-yellow-400',
			meaning: t('notStarted'),
		},
		{
			id: 4,
			className: 'bg-red-600',
			meaning: t('expired'),
		},
		{
			id: 5,
			className: 'bg-black',
			meaning: t('hasToDoToday'),
		},
		{
			id: 6,
			className: 'bg-gray-400',
			meaning: t('notReadyForNextStage'),
		},
	]

	const {
		data: productionWorker = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionSalesOrder',
			currentPage,
			pageSize,
			docEntry,
			isStarted,
			isReady,
			isReadyForNextStage,
		],
		queryFn: fetchProductionInSalesModal,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: !isFetching,
	})

	useEffect(() => {
		setProductionWorkersData((prev) => [...prev, ...productionWorker])
	}, [productionWorker])

	// const handleNextPage = () => {
	// 	if (productionWorkersData.length === 10) {
	// 		setCurrentPage((prevPage) => prevPage + 1)
	// 	}
	// }

	// const handlePreviousPage = () => {
	// 	if (currentPage > 0) {
	// 		setCurrentPage((prevPage) => prevPage - 1)
	// 	}
	// }

	useEffect(() => {
		const handleScroll = () => {
			if (tableRef.current) {
				const { scrollTop, scrollHeight, clientHeight } =
					tableRef.current
				if (
					scrollTop + clientHeight >= scrollHeight - 100 &&
					!isFetching
				) {
					setIsFetching(true)
					debounce(() => {
						setCurrentPage((prevPage) => prevPage + 1)
					}, 2000)
				}
			}
		}

		const tableContainer = tableRef.current
		if (tableContainer) {
			tableContainer.addEventListener('scroll', handleScroll)
		}

		return () => {
			if (tableContainer) {
				tableContainer.removeEventListener('scroll', handleScroll)
			}
		}
	}, [isFetching])

	useEffect(() => {
		if (isFetching) {
			refetch().finally(() => setIsFetching(false))
		}
	}, [currentPage, isFetching, refetch])

	const columns = columnProductionInSalesModal(t)

	return (
		<>
			<div className="px-4 ">
				<div className=" mt-10 mb-4  flex flex-col justify-between sm:flex-row">
					<div className="flex gap-5 flex-wrap">
						{meaningOfBg.map((item, index) => (
							<div
								key={index}
								className="flex gap-1 items-center"
							>
								<div
									className={`rounded-full ${item.className} w-[20px] h-[20px]`}
								></div>
								<span>{item.meaning}</span>
							</div>
						))}
					</div>
					{/* <div className="flex sm:mr-10">
						<button
							onClick={handlePreviousPage}
							disabled={currentPage === 0}
							className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
						>
							{t('previous')}
						</button>
						<div className="bg-[#099204] w-[20px] h-[30px] flex items-center justify-center">
							<span className="text-center text-white">
								{currentPage + 1}
							</span>
						</div>
						<button
							onClick={handleNextPage}
							disabled={productionWorkersData.length !== 10}
							className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
						>
							{t('next')}
						</button>
					</div> */}
				</div>

				<div
					ref={tableRef}
					className="max-h-[500px] mt-5 overflow-auto border rounded-lg shadow-md"
				>
					<Table
						columns={columns}
						dataSource={productionWorkersData}
						pagination={false}
						className="w-full ant-table-override"
						rowKey={(record) => record.docEntry}
						bordered
						scroll={{ x: 900 }}
						loading={isLoading || isFetching}
						rowClassName={(record) => {
							if (record.isReadyForNextStage !== 'Yes') {
								return 'bg-gray-500 hover:bg-gray-400 transition-colors duration-200'
							}
							if (
								record.isStarted === 'Yes' &&
								record.isReady === 'Yes'
							) {
								return 'bg-green-400  hover:bg-green-300 transition-colors duration-200'
							}
							if (
								record.isStarted === 'Yes' &&
								record.isReady === 'No'
							) {
								return 'bg-blue-400 hover:bg-blue-300 transition-colors duration-200'
							}
							if (
								record.isStarted === 'No' &&
								record.isReady === 'No' &&
								isSameDay(record.dueDate, formattedDate)
							) {
								return 'bg-black text-white hover:bg-gray-700 transition-colors duration-200'
							}
							if (
								record.isStarted === 'No' &&
								record.isReady === 'No' &&
								isBefore(record.dueDate, formattedDate)
							) {
								return 'bg-red-600 hover:bg-red-400 transition-colors duration-200'
							}

							if (
								record.isStarted === 'No' &&
								record.isReady === 'No' &&
								isAfter(record.dueDate, formattedDate)
							) {
								return 'bg-yellow-400 hover:bg-yellow-300 transition-colors duration-200'
							}

							return 'bg-white hover:bg-gray-300 transition-colors duration-200 '
						}}
					/>
				</div>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsModalVisible(false)
				}}
			/>
			<SuccessModalWithTimer
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModalWithTimer
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</>
	)
}

export default ProductionInViewSales
