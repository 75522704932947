import React, { useEffect, useState } from 'react'
import BarcodeGenerator from '../../../components/Barcode/index'
import { fetchSeriesBarcode } from '../../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import './main.css'
import moment from 'moment'
const SeriesBarcodePage = () => {
	const [docEntry, setDocEntry] = useState('')

	useEffect(() => {
		setTimeout(() => {
			window.print()
		}, 1000)
	}, [])

	const { data = [] } = useQuery({
		queryKey: ['seriesBarcode', docEntry],
		queryFn: fetchSeriesBarcode,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		const docEntrySeries = localStorage.getItem('seriesDocEntry')
		if (docEntrySeries) {
			setDocEntry(JSON.parse(docEntrySeries))
		}
	}, [docEntry])

	return (
		<div className="p-2 bg-white text-black font-sans w-[350px]">
			{data.map((item) =>
				item.documentLines.map((line) => (
					<div
						key={`${item.docNum}-${line.serialNumber}`}
						className="page-break"
					>
						<div className="flex flex-col gap-1 mt-3">
							<p className="text-sm font-extrabold text-[#000000]">
								{item.creatorName}
							</p>
							<div className="flex gap-5 items-center justify-between">
								<p className="font-nunito text-sm font-extrabold text-[#000000]">
									{item.docNum}
								</p>
								<div className="flex gap-1">
									<p>Дата:</p>
									<p className="font-nunito font-extrabold text-[#000000]">
										{item.dueDate}
									</p>
								</div>
							</div>
							<p className="text-[12px]">{item.itemName}</p>
							<div className="flex items-center gap-5 justify-between">
								<p className="text-sm font-extrabold">
									{`${line.width ? `${line.width} x ${line.hight}` : ''} `}
								</p>
								<div className="flex items-center gap-1">
									<p>Деталь:</p>
									<p className="text-sm font-extrabold">
										{line.serialNumber}
									</p>
								</div>
							</div>
						</div>
						<BarcodeGenerator
							docNum={line.serialNumber}
							displayValue={false}
							width={2}
							height={40}
							margin={0}
							fontSize={'10'}
							className="mt-3 text-left"
						/>
					</div>
				)),
			)}
		</div>
	)
}

export default SeriesBarcodePage
