import { Modal, Input, Button } from 'antd'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from '../Modal/SuccessModal'
import ErrorModal from '../Modal/ErrorModal'
const CreteUserModal = ({ visible, onClose, onConfirmPost }) => {
  const { t } = useTranslation()
  const successRef = useRef()
  const errorRef = useRef()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('+998 (')
  const [address, setAddress] = useState('')

  const resetForm = () => {
    setName('')
    setPhoneNumber('+998 (')
    setAddress('')
  }

  const postData = async () => {
    setLoading(true)
    const cleanedPhone = phoneNumber.replace(/[\(\)\-\_\" "]/g, '')

    try {
      const response = await http.post(
        'api/businesspartners/yetkazib-beruvchilar',
        {
          cardName: name,
          phoneNumber: cleanedPhone,
          address,
        },
      )
      const { data } = response
      console.log('Success:', data)

      if (onConfirmPost) {
        try {
          onConfirmPost({
            name: data?.cardName,
            phoneNumber: data?.cellular,
            cardCode: data?.cardCode,
          })
          resetForm()
        } catch (err) {
          console.error('Error in onConfirmPost:', err)
        }
      }

      try {
        successRef.current.open(t('sucCreated'))
      } catch (err) {
        console.error('Error in successRef:', err)
      }

      onClose()
    } catch (error) {
      console.error('Error during API call or later:', error)
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    let input = e.target.value.replace(/[^\d]/g, '')

    if (!input.startsWith('998')) {
      input = '998' + input
    }
    if (input.length <= 3) {
      input = `+${input}`
    } else if (input.length <= 5) {
      input = `+${input.slice(0, 3)} (${input.slice(3)}`
    } else if (input.length <= 8) {
      input = `+${input.slice(0, 3)} (${input.slice(3, 5)}) ${input.slice(5)}`
    } else if (input.length <= 10) {
      input = `+${input.slice(0, 3)} (${input.slice(3, 5)}) ${input.slice(5, 8)}-${input.slice(8)}`
    } else {
      input = `+${input.slice(0, 3)} (${input.slice(3, 5)}) ${input.slice(5, 8)}-${input.slice(8, 10)}-${input.slice(10, 12)}`
    }

    setPhoneNumber(input)
  }

  return (
    <Modal
      title={
        <h1 className="font-nunito  font-extrabold text-[#000000]">
          {t('addSupplier')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="40%"
      style={{ height: '70%' }}
    >
      <div className="w-full px-2 sm:px-14">
        <div className="mt-4 flex flex-col gap-3 sm:mt-5">
          <p>{t('supplierName')}</p>
          <Input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
          />
        </div>
        <div className="mt-4 flex flex-col gap-3 sm:mt-5">
          <p>{t('phoneNum')}</p>
          <Input
            type="text"
            value={phoneNumber}
            onChange={handleChange}
            className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
          />
        </div>
        <div className="mt-4 flex flex-col gap-3 sm:mt-5">
          <p>{t('Address')}</p>
          <Input
            type="text"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value)
            }}
            className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
          />
        </div>

        <div className="flex items-center gap-10 mt-14 justify-between">
          <Button
            onClick={() => {
              onClose()
            }}
            className="w-full bg-red-600 h-[35px] rounded-lg text-white"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={postData}
            loading={loading}
            disabled={loading}
            className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
          >
            {t('add')}
          </Button>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
        onConfirm={() => {
          resetForm()
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default CreteUserModal
