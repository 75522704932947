import { Input, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { http } from '../../../services/http'
import SuccessModal from '../SuccessModal'
import ErrorModal from '../ErrorModal'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

const DownPaymentsInfo = ({ docEntry }) => {
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const [dataDocEntry, setDataDocEntry] = useState('')

	const fetchDownPayments = async ({ queryKey }) => {
		const [_, docEntry] = queryKey
		if (docEntry) {
			try {
				let url = `api/sales/sales-order-incoming-payments?salesOrderDocEntry=${docEntry}`
				const { data } = await http.get(url)
				return data
			} catch (error) {
				console.log(error)
			}
		}
	}

	const { data: paymentData = [], isLoading } = useQuery({
		queryKey: ['salesPayments', dataDocEntry],
		queryFn: fetchDownPayments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	if (!docEntry) return null

	useEffect(() => {
		setDataDocEntry(docEntry)
	}, [docEntry])

	const columns = [
		{
			title: t('date2'),
			dataIndex: 'docDate',
			key: 'docDate',
			render: (text) => moment(text).format('DD.MM.YYYY'),
		},
		{
			title: t('cashs'),
			dataIndex: 'cashAcctName',
			key: 'cashAcctName',
			render: (text) => text,
		},
		{
			title: t('priceSum'),
			dataIndex: 'cashSum',
			key: 'cashSum',
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.docCur}
					</span>
				)
			},
		},
		{
			title: t('currency'),
			dataIndex: 'docCurr',
			key: 'docCurr',
		},
		{
			title: t('comments'),
			dataIndex: 'comments',
			key: 'comments',
		},
	]

	return (
		<div className="w-full px-4 ">
			<div className="w-full">
				<div className="flex items-center gap-10  ">
					<div className="mt-4 flex flex-col gap-3 sm:mt-0">
						<p className="font-bold">{t('paid')}</p>
						<Input
							value={`${paymentData?.dpDocTotal ?? ''} `}
							style={{ color: 'black' }}
							className="w-[200px]"
							disabled
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-0">
						<p className="font-bold">{t('debtTotal')}</p>
						<Input
							value={`${paymentData?.openAmount ?? ''} `}
							style={{ color: 'black' }}
							className="w-[200px]"
							disabled
						/>
					</div>
				</div>
				<Table
					columns={columns}
					dataSource={paymentData}
					pagination={false}
					className="border-1 mt-5 border-black"
					scroll={{ x: '100%' }}
					bordered
				/>
			</div>

			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</div>
	)
}

export default DownPaymentsInfo
