import { Modal, Space } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import SalesInfo from './salesInfo'
import DownPaymentsInfo from './downPaymentsInfo'
import BpInfo from './bpInfo'
import ProductionInViewSales from './Production'

const ViewSalesModal = ({
	visible,
	data,
	onClose,
	ship = false,
	incoming = false,
	refetch,
}) => {
	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				return (
					<SalesInfo
						data={data}
						onClose={onCloseModal}
						ship={ship}
						incoming={incoming}
						refetch={refetch}
					/>
				)
			case 'tab2':
				return <BpInfo cardCode={data?.cardCode} />
			case 'tab3':
				return <ProductionInViewSales docEntry={data?.docEntry} />
			case 'tab4':
				return <DownPaymentsInfo docEntry={data?.docEntry} />

			default:
				return null
		}
	}
	const { t } = useTranslation()

	const [activeTab, setActiveTab] = useState('tab1')

	const onCloseModal = () => {
		onClose()
		setActiveTab('tab1')
	}

	if (!data) return null

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('order')} № {data.docNum}
				</h1>
			}
			open={visible}
			onCancel={onCloseModal}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
		>
			<Space style={{ marginBottom: 16 }} className="mt-5">
				<button
					className={`${
						activeTab === 'tab1'
							? 'text-blue-900 underline underline-offset-8'
							: ''
					} font-semibold `}
					onClick={() => setActiveTab('tab1')}
				>
					{t('salesInfo')}
				</button>
				<button
					className={`${
						activeTab === 'tab2'
							? 'text-blue-900 underline underline-offset-8'
							: ''
					} font-semibold ml-3`}
					onClick={() => setActiveTab('tab2')}
				>
					{t('bpInfo')}
				</button>
				<button
					className={`${
						activeTab === 'tab3'
							? 'text-blue-900 underline underline-offset-8'
							: ''
					} font-semibold ml-3`}
					onClick={() => setActiveTab('tab3')}
				>
					{t('production')}
				</button>
				<button
					className={`${
						activeTab === 'tab4'
							? 'text-blue-900 underline underline-offset-8'
							: ''
					} font-semibold ml-3`}
					onClick={() => setActiveTab('tab4')}
				>
					{t('payments')}
				</button>
			</Space>
			<div className="mt-5">{renderContent()}</div>
		</Modal>
	)
}

export default ViewSalesModal
