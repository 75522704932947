import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnSalesOrder } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewSalesModal from '../../../components/Modal/ViewSalesModal/index'
import { useQuery } from '@tanstack/react-query'
import { fetchSales } from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'
import { useSelector } from 'react-redux'
import {
	handleNavigate,
	handleNavigateCar,
	handleNavigateSeries,
} from '../../../utils/navigateToPdf'
const ShippedSales = () => {
	const { t } = useTranslation()
	const { roleNames, department } = useSelector((state) => state.main)
	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [cardName, setCardName] = useState('')
	const [slpName, setSlpName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)

	const { data = [], isLoading } = useQuery({
		queryKey: [
			'shippedSales',
			currentPage,
			cardName,
			docNum,
			startDate,
			endDate,
			slpName,
			roleNames.includes('Supplier') ? '' : department,
			'api/sales/ortib-yuborilgan',
		],
		queryFn: fetchSales,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName)
	}

	const columns = columnSalesOrder(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange,
		handleNavigate,
		handleNavigateCar,
		handleNavigateSeries,
	)

	return (
		<Layout>
			<Header
				title={t('Shipped')}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
			/>
			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>
		</Layout>
	)
}

export default ShippedSales
