import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table, Card, Input, Select } from 'antd'
import { Timer } from 'lucide-react'
import ViewProductionWorkersModal from '../../../../components/Modal/viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../../../components/Modal/SuccessModalWithTimer'
import ErrorModalWithTimer from '../../../../components/Modal/ErrorModalWithTimer'
import { columnProductionWorkersNotReady } from '../../../../utils/columns.jsx'
import { fetchProductionWorkers } from '../../../../utils/fetchData.js'
import moment from 'moment'
import { http } from '../../../../services/http'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import '../table.css'
import { debouncedSet } from '../../../../utils/debounce.js'
import {
	handleMarshrutWorkers,
	handleNavigateSeriesProdWorkers,
} from '../../../../utils/navigateToPdf'
import {
	calculateDurationString,
	getToday,
	formatTime,
	isBefore,
	isSameDay,
	isAfter,
} from '../../../../utils/time'

const IsDoing = () => {
	const { t } = useTranslation()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const successRef = useRef()
	const errorRef = useRef()
	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [inputDocNum, setInputDocNum] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)
	const [pageSize] = useState('10')
	const [activeTimers, setActiveTimers] = useState({})
	const [scannedCode, setScannedCode] = useState('')
	const [lastScanTime, setLastScanTime] = useState(null)
	const [mappedData, setMappedData] = useState([])
	const [isReadyForNextStage, setIsReadyForNextStage] = useState('No')
	const [isStarted, setIsStarted] = useState('Yes')
	const [isReady, setIsReady] = useState('')

	const ignoredKeys = [
		'Backspace',
		'Tab',
		'Escape',
		'Delete',
		'ArrowLeft',
		'ArrowRight',
		'ArrowUp',
		'ArrowDown',
		'F1',
		'F2',
		'F3',
		'F4',
		'F5',
		'F6',
		'F7',
		'F8',
		'F9',
		'F10',
		'F11',
		'F12',
		'Pause',
		'ScrollLock',
	]

	const meaningOfBg = [
		{
			id: 1,
			className: 'bg-green-400',
			meaning: t('done'),
		},
		{
			id: 2,
			className: 'bg-blue-400',
			meaning: t('inProcess2'),
		},
		{
			id: 3,
			className: 'bg-yellow-400',
			meaning: t('notStarted'),
		},
		{
			id: 4,
			className: 'bg-red-600',
			meaning: t('expired'),
		},
		{
			id: 5,
			className: 'bg-black',
			meaning: t('hasToDoToday'),
		},
		{
			id: 6,
			className: 'bg-gray-400',
			meaning: t('notReadyForNextStage'),
		},
	]

	const {
		data: productionWorkersData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionWorkersDoing',
			currentPage,
			pageSize,
			startDate,
			endDate,
			docNum,
			isStarted,
			isReady,
			isReadyForNextStage,
			roleNames.includes('admin') ||
			roleNames.includes('ProdManager') ||
			roleNames.includes('Worker')
				? ''
				: getMe?.department,
			roleNames.includes('admin') ? '' : getMe?.u_Service,
		],
		queryFn: fetchProductionWorkers,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		setMappedData(productionWorkersData)
	}, [productionWorkersData])

	const handleNextPage = () => {
		if (mappedData.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}
	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const columns = columnProductionWorkersNotReady(
		t,
		handleNavigateSeriesProdWorkers,
		handleDocNumChange,
		handleMarshrutWorkers,
		false,
		false,
	)

	return (
		<div key={'Doing'}>
			<div className="mt-5 ml-2 sm:ml-10 flex gap-5 items-end">
				<div className="flex flex-col gap-3">
					<span className="font-bold text-sm">{t('docNum')}</span>
					<Input
						className="w-full sm:w-[250px] h-8"
						placeholder={t('docNum')}
						onChange={(e) => {
							setInputDocNum(e.target.value)
							handleDocNumChange(e.target.value)
						}}
						value={inputDocNum}
					/>
				</div>
				<div className="flex flex-col gap-3">
					<p className="font-bold text-sm">{t('startDate')}</p>
					<Input
						type="date"
						value={startDate}
						className="w-[150px] h-8"
						onChange={handleStartDate}
						max={endDate}
						allowClear
					/>
				</div>
				<div className="flex flex-col gap-3">
					<p className="font-bold text-sm">{t('endDate')}</p>
					<Input
						type="date"
						value={endDate}
						className="w-[150px] h-8"
						onChange={handleEndDate}
						min={startDate}
						max={formattedDate}
						allowClear
					/>
				</div>
			</div>

			<div className=" mt-10 mb-4 ml-10 flex flex-col justify-between sm:flex-row">
				<div className="flex gap-5 flex-wrap">
					{meaningOfBg.map((item, index) => (
						<div key={index} className="flex gap-1 items-center">
							<div
								className={`rounded-full ${item.className} w-[20px] h-[20px]`}
							></div>
							<span>{item.meaning}</span>
						</div>
					))}
				</div>
				<div className="flex sm:mr-10">
					<button
						onClick={handlePreviousPage}
						disabled={currentPage === 0}
						className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
					>
						{t('previous')}
					</button>
					<div className="bg-[#099204] w-[20px] h-[30px] flex items-center justify-center">
						<span className="text-center text-white">
							{currentPage + 1}
						</span>
					</div>
					<button
						onClick={handleNextPage}
						disabled={mappedData.length !== 10}
						className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
					>
						{t('next')}
					</button>
				</div>
			</div>

			<div className="ml-10">
				<Table
					columns={columns}
					dataSource={mappedData}
					pagination={false}
					className="mt-6 w-full sm:mt-10 ant-table-override"
					rowKey={(record) => record.docEntry}
					bordered
					scroll={{ x: 900 }}
					loading={isLoading}
					rowClassName={(record) => {
						if (record.isReadyForNextStage !== 'Yes') {
							return 'bg-gray-500 hover:bg-gray-400 transition-colors duration-200'
						}
						if (
							record.isStarted === 'Yes' &&
							record.isReady === 'Yes'
						) {
							return 'bg-green-400  hover:bg-green-300 transition-colors duration-200'
						}
						if (
							record.isStarted === 'Yes' &&
							record.isReady === 'No'
						) {
							return 'bg-blue-400 hover:bg-blue-300 transition-colors duration-200'
						}
						if (
							record.isStarted === 'No' &&
							record.isReady === 'No' &&
							isSameDay(record.dueDate)
						) {
							return 'bg-black text-white hover:bg-gray-700 transition-colors duration-200'
						}
						if (
							record.isStarted === 'No' &&
							record.isReady === 'No' &&
							isBefore(record.dueDate)
						) {
							return 'bg-red-600 hover:bg-red-400 transition-colors duration-200'
						}

						if (
							record.isStarted === 'No' &&
							record.isReady === 'No' &&
							isAfter(record.dueDate)
						) {
							return 'bg-yellow-400 hover:bg-yellow-300 transition-colors duration-200'
						}

						return 'bg-white hover:bg-gray-300 transition-colors duration-200 '
					}}
				/>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsModalVisible(false)
				}}
			/>
			<SuccessModalWithTimer
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModalWithTimer
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</div>
	)
}

export default IsDoing
