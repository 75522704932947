import { Modal, Input, Table } from 'antd'
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
const ViewProductionWorkersModal = ({ visible, data, onClose }) => {
	const [tableData, setTableData] = React.useState(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (data) {
			setTableData([data])
		}
	}, [data])

	if (!data) return null

	const columns = [
		{
			title: t('service'),
			dataIndex: 'description',
			key: 'description',
			width: 150,
		},
		{
			title: t('serialNumber'),
			dataIndex: 'serialNumber',
			key: 'serialNumber',
		},

		{
			title: t('startTime'),
			dataIndex: 'startTime',
			key: 'startTime',
		},

		{
			title: t('endTime'),
			dataIndex: 'endTime',
			key: 'endTime',
		},

		{
			title: t('wastedTime'),
			dataIndex: 'wastedTime',
			key: 'wastedTime',
		},
	]

	const closeModal = () => {
		onClose()
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('order')} № {data?.docNum}
				</h1>
			}
			open={visible}
			onCancel={closeModal}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-start justify-between sm:flex-row">
						<div className="flex flex-col gap-3 sm:w-[250px]">
							<p className="font-bold">{t('priority')}</p>
							<Input
								value={data?.priority}
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						<div className="flex flex-col gap-3">
							<p className="font-bold">{t('status')}</p>
							<Input
								value={data?.status}
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						<div className="flex flex-col gap-3">
							<p className="font-bold">{t('item')}</p>
							<Input
								value={data?.itemName}
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t('dueDate')}</p>
							<Input
								type="text"
								value={data?.dueDate}
								style={{ color: 'black' }}
								disabled
							/>
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: '100%' }}
						bordered
					/>
				</div>
			</div>
		</Modal>
	)
}

export default memo(ViewProductionWorkersModal)
