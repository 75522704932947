import * as React from 'react'
const ProductionIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.0}
			d="M15 14H9M12 17v-6"
		/>
		<path
			stroke="white"
			strokeLinejoin="round"
			strokeWidth={1.0}
			d="M22 18V8.403a4 4 0 0 0-.877-2.499l-1.922-2.403A4 4 0 0 0 16.078 2H7.923a4 4 0 0 0-3.124 1.501L2.877 5.904A4 4 0 0 0 2 8.404V18a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4Z"
		/>
		<path
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.0}
			d="M3 7h18"
		/>
	</svg>
)
export default ProductionIcon
