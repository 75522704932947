const handleNavigate = (record) => {
	sessionStorage.setItem('orderData', JSON.stringify(record))

	const url = `/orders`
	window.open(url, '_blank')
}

const handleNavigateCar = (record) => {
	sessionStorage.setItem('orderData', JSON.stringify(record))

	const url = `/marshrut`
	window.open(url, '_blank')
}

const handleNavigateSeries = (record) => {
	console.log('record', record)
	localStorage.setItem('seriesDocEntry', JSON.stringify(record.docEntry))
	const url = `/series-barcode`
	window.open(url, '_blank')
}

const handleMarshrutWorkers = (record) => {
	console.log('record', record)
	localStorage.setItem(
		'marshrutDocEntry',
		JSON.stringify(record.salesOrderDocEntry),
	)
	const url = `/marshrut-for-workers`
	window.open(url, '_blank')
}

const handleNavigateSeriesProdManager = (record) => {
	console.log('record', record)
	localStorage.setItem('seriesData', JSON.stringify(record))
	const url = `/prod-manager-series-barcode`
	window.open(url, '_blank')
}

const handleNavigateSeriesProdWorkers = (record) => {
	console.log('record', record)
	localStorage.setItem('seriesData', JSON.stringify(record))
	const url = `/series-one-barcode`
	window.open(url, '_blank')
}

export {
	handleNavigate,
	handleNavigateCar,
	handleNavigateSeries,
	handleMarshrutWorkers,
	handleNavigateSeriesProdManager,
	handleNavigateSeriesProdWorkers,
}
