import React from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'

export default function ConfirmDeleteModal({
  title = '',
  visible,
  handleClickCancel,
  handleClickNo,
  handleClickYes,
}) {
  const { t } = useTranslation()

  return (
    <Modal open={visible} onCancel={handleClickCancel} footer={null}>
      <div className="flex flex-col items-center justify-between m-5">
        <p className="font-bold text-lg text-center">{title}</p>

        <div className="flex items-center gap-5 mt-5">
          <Button
            className="bg-red-600 text-white"
            key="no"
            onClick={handleClickNo}
          >
            {t('no')}
          </Button>

          <Button
            key="yes"
            className="bg-[#0A4D68] text-white"
            onClick={handleClickYes}
          >
            {t('yes')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
