import React, { memo, useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import ErrorImage from '../../../assets/images/error.png'
import ErrorModalStyle from './ErrorModalStyle'

const ErrorModal = ({ getRef = () => {}, onConfirm = () => {} }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')

  useEffect(() => {
    const ref = {
      open: (t) => {
        setIsOpenModal(true)
        setErrorTitle(t)
      },
      close: () => setIsOpenModal(false),
    }
    getRef(ref)
  }, [getRef])

  return (
    <Modal
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      footer={null}
      centered
      closeIcon={<span style={{ fontSize: '1.5rem' }}>×</span>}
    >
      <ErrorModalStyle>
        <div className="card">
          <div className="flex justify-center">
            <img src={ErrorImage} alt="error image" className="img" />
          </div>
          <h2 className="title">{errorTitle}</h2>
          <div className="centerCard">
            <Button
              type="primary"
              danger
              className="bg-red-600 w-[100px] text-sm h-12 text-white"
              onClick={() => {
                onConfirm()
                setIsOpenModal(false)
              }}
            >
              {'Понятно'}
            </Button>
          </div>
        </div>
      </ErrorModalStyle>
    </Modal>
  )
}

export default memo(ErrorModal)
