import React, { useEffect, useRef } from 'react'
import JsBarcode from 'jsbarcode'

const BarcodeGenerator = ({
	docNum,
	displayValue = true,
	fontSize = 20,
	margin = 30,
	width = 3,
	height = 90,
	className = 'mt-5',
}) => {
	const barcodeRef = useRef(null)

	useEffect(() => {
		if (barcodeRef.current) {
			JsBarcode(barcodeRef.current, docNum, {
				format: 'CODE128',
				lineColor: '#000', // Line color
				width: width, // Bar width
				height: height, // Bar height
				displayValue: displayValue, // Display the value below the barcode
				fontSize: fontSize, // Font size for the value
				margin: margin, // Margin around the barcode
				text: docNum,
			})
		}
	}, [docNum])

	return (
		<div className={`${className} `}>
			<svg ref={barcodeRef}></svg>
		</div>
	)
}

export default BarcodeGenerator
