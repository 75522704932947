import React, { useEffect } from 'react'
import { InitOneSignal } from './utils/initOneSignal'
import RoutesComponent from './routes/routes'
import './index.css'
import PathListener from './components/path-listeners'

export default function App() {
	useEffect(() => {
		try {
			InitOneSignal()
			console.log('OneSignal Initialized in App.jsx')
		} catch (e) {
			console.log(e)
		}
	}, [])
	return (
		<>
			<PathListener />
			<RoutesComponent />
		</>
	)
}
