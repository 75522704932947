import { Modal, Input, Button, Select } from 'antd'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { fetchUoMGroups } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'

const CreteServicesItemModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation()
	const successRef = useRef()
	const errorRef = useRef()

	const [loading, setLoading] = useState(false)
	const [itemName, setItemName] = useState('')
	const [unitMeasure, setUnitMeasure] = useState('')
	const [u_Number, setU_Number] = useState('')

	const {
		data: uomData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['uoMEntry'],
		queryFn: fetchUoMGroups,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const resetForm = () => {
		setItemName('')
		setUnitMeasure('')
		setU_Number('')
	}

	const postData = async () => {
		setLoading(true)

		try {
			await http.post('api/items/add-item', {
				itemName: itemName,
				itemType: 'L',
				itemsGroupCode: '101',
				uoMGroupEntry: unitMeasure,
				u_Number: String(u_Number),
			})
			successRef.current.open(t('sucCreated'))
			onClose()
			resetForm()
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	if (!data) return null

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('AddProduct')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			style={{ height: '70%' }}
			bodyStyle={{ height: 'calc(100% - 55px)', overflowY: 'auto' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex flex-col items-center gap-10 sm:flex-row">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{t('itemName')}</p>
						<Input
							value={itemName}
							type="text"
							onChange={(e) => {
								setItemName(e.target.value)
							}}
							className="flex h-10 min-w-[200px] w-full  items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className=" flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('unitMsr')}`}</p>
						<Select
							type="text"
							value={unitMeasure}
							onChange={(val) => {
								setUnitMeasure(val)
							}}
							className="flex h-10 w-full  items-center justify-center border-[#D0D0D0]"
							options={uomData.map((item) => ({
								label: item.ugpName,
								value: item.ugpEntry,
							}))}
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{t('u_Number')}</p>
						<Input
							value={u_Number}
							type="number"
							onWheel={(e) => e.target.blur()}
							onChange={(e) => {
								setU_Number(e.target.value)
							}}
							className="flex h-10 w-full  items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('cancel')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('add')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default CreteServicesItemModal
