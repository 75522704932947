import { Modal, Input, Button, Select } from 'antd'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { fetchItemsGroups, fetchUoMGroups } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
const CreteItemAdminModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation()
	const successRef = useRef()
	const errorRef = useRef()

	const [loading, setLoading] = useState(false)
	const [width, setWidth] = useState('')
	const [height, setHeight] = useState('')
	const [itemName, setItemName] = useState('')
	const [itemsGroup, setItemsGroup] = useState('')
	const [minQuantity, setMinQuantity] = useState('')
	const [maxQuantity, setMaxQuantity] = useState('')
	const [ugpName, setUgpName] = useState('')
	const [currency, setCurrency] = useState('')
	const [price, setPrice] = useState('')
	const [koef, setKoef] = useState('')

	const postData = async () => {
		setLoading(true)

		try {
			await http.post('api/items/add-item-admin', {
				itemName,
				itemType: 'I', // I = Item
				itemsGroupCode: itemsGroup,
				currency: currency,
				price: price,
				u_Width: width,
				u_Height: height,
				u_Kvmetr: (height * width) / 1000000,
				minInventory: minQuantity,
				maxInventory: maxQuantity,
				uoMGroupEntry: ugpName,
				u_Number: '',
				u_DefaultCoefficient: Number(koef),
			})
			successRef.current.open(t('sucCreated'))
			onClose()
			resetForm()
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	const { data: itemsGroupData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['itemsGrp'],
		queryFn: fetchItemsGroups,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const {
		data: uomData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['uoMEntry'],
		queryFn: fetchUoMGroups,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const resetForm = () => {
		setWidth('')
		setHeight('')
		setItemName('')
		setItemsGroup('')
		setMinQuantity('')
		setMaxQuantity('')
		setUgpName('')
		setCurrency('')
		setPrice('')
		setKoef('')
	}

	if (!data) return null

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('AddProduct')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="50%"
			style={{ height: '70%' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center gap-5 justify-between">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5">
						<p>{t('itemName')}</p>
						<Input
							value={itemName}
							type="text"
							onChange={(e) => {
								setItemName(e.target.value)
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('Koefitsent')}`}</p>
						<Input
							value={koef}
							type="number"
							onChange={(e) => {
								setKoef(e.target.value)
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>

				<div className="flex items-center gap-5 justify-between">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('unitMsr')}`}</p>
						<Select
							type="text"
							value={ugpName}
							onChange={(val) => {
								setUgpName(val)
							}}
							loading={isLoading}
							className="flex h-10 w-full  items-center justify-center border-[#D0D0D0]"
							options={uomData.map((item) => ({
								label: item.ugpName,
								value: item.ugpEntry,
							}))}
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{t('productGroup')}</p>
						<Select
							value={itemsGroup}
							loading={itemsLoading}
							onChange={(value) => setItemsGroup(value)}
							className="w-full h-10"
						>
							{itemsGroupData
								.filter(
									(item) =>
										item.itmsGrpCod === '100' ||
										item.itmsGrpCod === '103' ||
										item.itmsGrpCod === '104' ||
										item.itmsGrpCod === '105',
								)
								.map((supplier) => (
									<Select.Option
										key={supplier.itmsGrpCod}
										value={supplier.itmsGrpCod}
									>
										{supplier.itmsGrpNam}
									</Select.Option>
								))}
						</Select>
					</div>
				</div>
				<div className="flex items-center gap-5 justify-between">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('currency')}`}</p>
						<Select
							value={currency}
							onChange={(value) => setCurrency(value)}
							className="w-full h-10"
							options={[
								{
									value: 'UZS',
									label: 'UZS',
								},
							]}
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('price')}`}</p>
						<Input
							value={price}
							type="text"
							onChange={(e) => {
								setPrice(e.target.value)
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex items-center gap-5 justify-between">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('width')} (${t('mm')})`}</p>
						<Input
							value={width}
							type="text"
							onChange={(e) => {
								setWidth(e.target.value)
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('height')} (${t('mm')})`}</p>
						<Input
							value={height}
							type="text"
							onChange={(e) => {
								setHeight(e.target.value)
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>

				<div className="flex items-center gap-5 justify-between">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('minQuantity')}`}</p>
						<Input
							value={minQuantity}
							type="text"
							onChange={(e) => {
								setMinQuantity(e.target.value)
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('maxQuantity')}`}</p>
						<Input
							value={maxQuantity}
							type="text"
							onChange={(e) => {
								setMaxQuantity(e.target.value)
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('cancel')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('add')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default CreteItemAdminModal
