import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
} from '@ant-design/icons'
import {
	setCollapse,
	setSelectedPath,
	setOpenMiniMenu,
} from '../../slice/mainSlice'
import logo from '../../assets/images/BIS logo.svg'
import '../../assets/style/menu.css'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SalesIcon from '../../assets/icons/SalesSvg'
import PaymentIcon from '../../assets/icons/PaymentSvg'
import ClientIcon from '../../assets/icons/ClientSvg'
import SalesStaticsIcon from '../../assets/icons/SalesStatics'
import ReportsIcon from '../../assets/icons/ReportsSvg'
import ItemsIcon from '../../assets/icons/itemsSvg'
import { get } from 'lodash'
import SalesIcon2 from '../../assets/icons/SalesSvg2'
import ProductionIcon from '../../assets/icons/ProductionSvg'
import AccountsIcon from '../../assets/icons/AccountsSvg'
import PricingIcon from '../../assets/icons/PricingSvg'
import HRIcon from '../../assets/icons/HRSvg.jsx'
import DeliveryIcon from '../../assets/icons/DeliverySvg.jsx'

export const Menubar = () => {
	const { t } = useTranslation()
	const { collapse, selectedPath, openMiniMenu, getMe } = useSelector(
		(state) => state.main,
	)
	const role = get(getMe, 'roleLines', [])
	const dispatch = useDispatch()
	const [menuHeight, setMenuHeight] = useState('auto')
	const navigate = useNavigate()

	const pages = {
		Wrhmanager: [
			{
				key: 'purchasement',
				label: t('purchasement'),
				icon: <SalesIcon />,
				children: [
					{
						key: 'purchase-order',
						label: t('purchase-order'),
					},
					{
						key: 'purchase-incoming',
						label: t('purchase-incoming'),
					},
				],
			},

			{
				key: 'sales',
				label: t('sales'),
				icon: <SalesIcon2 />,

				children: [
					{
						key: 'sales-order',
						label: t('SalesOrder'),
					},
					{
						key: 'shipped-sales',
						label: t('Shipped'),
					},
				],
			},
			{
				key: 'stock-transfers',
				label: t('stockTransfers'),
				icon: <DeliveryIcon />,
			},
		],
		'Sales Manager': [
			{
				key: 'saleStatics',
				label: t('saleStatics'),
				icon: <SalesStaticsIcon />,
				children: [
					{
						key: 'my-sales',
						label: t('MySales'),
					},
					{
						key: 'debtors',
						label: t('Accounts receivable'),
					},
				],
			},
			{
				key: 'sales',
				label: t('sales'),
				icon: <SalesIcon2 />,
				children: [
					{
						key: 'sales-order',
						label: t('SalesOrder'),
					},
					{
						key: 'shipped-sales',
						label: t('Shipped'),
					},
					{
						key: 'finished-sales',
						label: t('finishedSales'),
					},
					{
						key: 'ignored-sales-orders',
						label: t('ignoredSales'),
					},
				],
			},
			{
				key: 'clients',
				label: t('clients'),
				icon: <ClientIcon />,
			},
		],
		Supplier: [
			{
				key: 'purchasement',
				label: t('purchasement'),
				icon: <SalesIcon />,
				children: [
					{
						key: 'purchase-order',
						label: t('purchase-order'),
					},
					{
						key: 'purchase-incoming',
						label: t('purchase-incoming'),
					},
					{
						key: 'purchase-invoices',
						label: t('purchase-invoices'),
					},
				],
			},

			{
				key: 'outgoing-payments',
				label: t('outgoingPayment'),
				icon: <PaymentIcon />,
			},

			{
				key: 'reports',
				label: t('reports'),
				icon: <ReportsIcon />,
				children: [
					{
						key: 'reports',
						label: t('reportsWhs'),
					},
					{
						key: 'send-money',
						label: t('sendMoney'),
					},
				],
			},
			{
				key: 'items',
				label: t('items'),
				icon: <ItemsIcon />,
			},
			{
				key: 'suppliers',
				label: t('suppliers'),
				icon: (
					<UserOutlined
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
			},
		],
		Cashier: [
			{
				key: 'sales',
				label: t('sales'),
				icon: <SalesIcon2 />,

				children: [
					{
						key: 'sales-order',
						label: t('SalesOrder'),
					},
					{
						key: 'sales',
						label: t('sales'),
					},
				],
			},
			{
				key: 'payments',
				label: t('payments'),
				icon: <PaymentIcon />,
				children: [
					{
						key: 'outgoing-payments',
						label: t('outgoingPayment'),
					},
					{
						key: 'incoming-payments',
						label: t('incomingPayment'),
					},
				],
			},
			{
				key: 'cash',
				label: t('kassa'),
				icon: <PaymentIcon />,
				children: [
					{
						key: 'balance',
						label: t('balance'),
					},
					{
						key: 'send-money',
						label: t('sendMoney'),
					},
				],
			},
		],
		ProdManager: [
			{
				key: 'production-orders',
				label: t('productionOrders'),
				icon: <ProductionIcon />,
			},
		],
		Worker: [
			{
				key: 'production-workers',
				label: t('production'),
				icon: <ProductionIcon />,
			},
		],
		admin: [
			{
				key: 'whs',
				label: t('whs'),
				icon: <ReportsIcon />,
				children: [
					{
						key: 'reports',
						label: t('warehouses'),
					},
					{
						key: 'itemsAdmin',
						label: t('items'),
					},
					{
						key: 'itemsServices',
						label: t('services'),
					},
					{
						key: 'itemsShapes',
						label: t('shapes'),
					},
					{
						key: 'package-services',
						label: t('packageServices'),
					},

					{
						key: 'stock-transfers',
						label: t('stockTransfers'),
					},
					{
						key: 'purchase-order',
						label: t('purchase-order'),
					},
					{
						key: 'purchase-incoming',
						label: t('purchase-incoming'),
					},
					{
						key: 'purchase-invoices',
						label: t('purchase-invoices'),
					},
				],
			},
			{
				key: 'sales',
				label: t('salesDepartment'),
				icon: <SalesIcon2 />,
				children: [
					{
						key: 'sales-order',
						label: t('orders'),
					},
					{
						key: 'sales-order-request',
						label: t('salesOrderRequest'),
					},
					{
						key: 'ignored-sales-orders',
						label: t('ignoredSales'),
					},
					{
						key: 'clients',
						label: t('clients'),
					},
					{
						key: 'branches',
						label: t('branches'),
					},
					{
						key: 'itemsServices',
						label: t('services'),
					},
					{
						key: 'package-services',
						label: t('packageServices'),
					},
				],
			},
			{
				key: 'production',
				label: t('production'),
				icon: <ProductionIcon />,
				children: [
					{
						key: 'production-orders',
						label: t('productionOrders'),
					},
					{
						key: 'prod-workers-admin',
						label: t('productionWorkersAdmin'),
					},
				],
			},

			{
				key: 'accounting',
				label: t('accounting'),
				icon: <AccountsIcon />,
				children: [
					{
						key: 'expected-payments',
						label: t('expectedPayments'),
					},
					{
						key: 'incoming-payments',
						label: t('incomingAdmin'),
					},
					{
						key: 'cashs',
						label: t('cashs'),
					},

					{
						key: 'outgoing-payments',
						label: t('expenses'),
					},

					{
						key: 'debtors',
						label: t('Accounts receivable'),
					},
				],
			},
			{
				key: 'HR',
				label: t('HR'),
				icon: <HRIcon />,
				children: [
					{
						key: 'employees',
						label: t('employees'),
					},
				],
			},

			{
				key: 'pricing',
				label: t('pricing'),
				icon: <PricingIcon />,
				children: [
					{
						key: 'itemsTable',
						label: t('itemsTable'),
					},
					{
						key: 'itemsShapesTable',
						label: t('itemsShapesTable'),
					},
					{
						key: 'servicesTable',
						label: t('servicesTable'),
					},
				],
			},
			{
				key: 'debtors',
				label: t('Accounts receivable'),
				icon: (
					<UserOutlined
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
			},

			// {
			//   key: 'suppliers',
			//   label: t('suppliers'),
			//   icon: <UserOutlined style={{ fontSize: '25px', color: 'white' }} />,
			// },
		],
	}

	useEffect(() => {
		const menu = document.getElementById('menu')
		if (menu) {
			setMenuHeight(`${menu.scrollHeight}px`)
		}
	}, [collapse])

	const toggleCollapsed = () => {
		dispatch(setCollapse(!collapse))
	}
	const onSelectMenuItem = (item) => {
		dispatch(setSelectedPath(item.key))
		navigate(`/${item.key}`)
	}

	const uniquePages = () => {
		const combinedPages = Array.isArray(role)
			? role.flatMap(({ roleName }) => pages[roleName] || [])
			: []

		const pageMap = new Map()
		combinedPages.forEach((page) => {
			if (!pageMap.has(page.key)) {
				pageMap.set(page.key, page)
			}
		})

		return Array.from(pageMap.values())
	}

	const items = uniquePages()

	return (
		<div className="flex m-0 h-screen">
			<div
				className={`${collapse ? 'w-[80px]' : 'w-[295px]'} h-${menuHeight} bg-[#0A4D68]`}
			>
				{/* Main Menu */}
				<div className="flex flex-col  h-screen">
					<div className="flex justify-between px-5 mt-6 ">
						{collapse ? (
							''
						) : (
							<img src={logo} alt="" className=" w-[150px]" />
						)}

						<button
							onClick={toggleCollapsed}
							className="bg-[#0A4D68] text-white text-2xl"
						>
							{collapse ? (
								<MenuUnfoldOutlined className="ml-2" />
							) : (
								<MenuFoldOutlined />
							)}
						</button>
					</div>
					<div className="flex-1 overflow-y-auto mt-14">
						<Menu
							defaultOpenKeys={openMiniMenu}
							selectedKeys={[selectedPath]}
							mode="inline"
							inlineCollapsed={collapse}
							items={items}
							className="bg-transparent"
							theme="light"
							onSelect={onSelectMenuItem}
							onOpenChange={(v) => {
								dispatch(setOpenMiniMenu(v))
							}}
						/>
					</div>

					{/* Footer Menu */}
					{role.some(({ roleName }) => roleName === 'Worker') && (
						<div
							className="border-t border-gray-300 "
							id="Something"
						>
							<Menu
								mode="inline"
								inlineCollapsed={collapse}
								selectedKeys={[selectedPath]}
								items={[
									{
										key: 'production-workers-done',
										label: t('finishedProductions'),
										icon: <ProductionIcon />,
									},
								]}
								className="bg-transparent"
								theme="light"
								onSelect={onSelectMenuItem}
								onOpenChange={(v) => {
									dispatch(setOpenMiniMenu(v))
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Menubar
