import * as React from 'react'
const PricingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      stroke="white"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M21 10V7.5a4 4 0 0 0-4-4H7a4 4 0 0 0-4 4V18a4 4 0 0 0 4 4h2M8 2v3m8 4H8m6 4H8m2 4H8m8-15v3m-4 17 3.06-.873c.165-.046.314-.134.435-.255l5.015-5.015a1.674 1.674 0 0 0-2.367-2.367l-5.015 5.015c-.12.12-.209.27-.255.434L12 22Z"
    />
  </svg>
)
export default PricingIcon
