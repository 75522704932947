import { useTranslation } from 'react-i18next'
import { Table, Input } from 'antd'
import '../assets/style/antTable.css'

// This is Main Components in the 95% Menus We use This Component The UI Of The Screens with table Is Here Only 5 Screens Don't Use This Component This are: 1.Reports,2.Cash => Balance,3.ProdWorkers,4.ProdWorkersDone,5.ProdWorkersAdmin

function Header({
	title,
	currentPage,
	handlePreviousPage,
	handleNextPage,
	hasMoreData,
	columns,
	fdata,
	loading,
	handleRowClick,
	onBtnClick,
	onStartDateChange,
	onEndDateChange,
	startDate,
	endDate,
	formattedDate,
	isSticky = true,
	isPayVendor = false,
	onPayVendorClick,
	scrollX = 900,
	onStartDueDateChange,
	onEndDueDateChange,
	startDueDate,
	endDueDate,
}) {
	const { t } = useTranslation()

	return (
		<div className="m-5 border-[2px] border-gray-200">
			<div className="flex w-fullitems-center justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
				<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">
					{t(title)}
				</h1>
				<div className="flex items-center gap-5 flex-col sm:flex-row">
					{isPayVendor && (
						<button
							onClick={onPayVendorClick}
							className="h-[30px] w-auto p-1  bg-[#099204] rounded-lg text-white "
						>
							{t('payVendor')}
						</button>
					)}
					{onBtnClick && (
						<button
							onClick={onBtnClick}
							className="h-[30px] w-[140px]  bg-[#099204] rounded-lg text-white "
						>
							{t('Add')}
						</button>
					)}
				</div>
			</div>

			<div className="mt-8 w-full border-[1px] border-[#E8E8E8] sm:mt-8"></div>

			<div className="flex w-full flex-col sm:flex-row items-center justify-between gap-4 px-4  sm:gap-7 sm:px-10">
				{onEndDateChange && (
					<div className=" mt-5 flex gap-5 flex-col sm:flex-row">
						<div className="flex flex-col gap-3">
							<p>{t('startDate')}</p>
							<Input
								type="date"
								value={startDate}
								className="w-[150px]"
								onChange={onStartDateChange}
								max={endDate}
								allowClear
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p>{t('endDate')}</p>
							<Input
								type="date"
								value={endDate}
								className="w-[150px]"
								onChange={onEndDateChange}
								min={startDate}
								max={formattedDate}
								allowClear
							/>
						</div>
					</div>
				)}
				{onStartDueDateChange && (
					<div className=" mt-5 flex gap-5 flex-col sm:flex-row">
						<div className="flex flex-col gap-3">
							<p>{t('startDueDate')}</p>
							<Input
								type="date"
								value={startDueDate}
								className="w-[150px]"
								onChange={onStartDueDateChange}
								max={endDueDate}
								allowClear
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p>{t('endDueDate')}</p>
							<Input
								type="date"
								value={endDueDate}
								className="w-[150px]"
								onChange={onEndDueDateChange}
								min={startDueDate}
								max={formattedDate}
								allowClear
							/>
						</div>
					</div>
				)}
			</div>
			<div className="mt-6 sm:ml-10 sm:mt-10">
				{handleNextPage && (
					<div className="mb-4 flex flex-col justify-between sm:flex-row">
						<div className="font-nunitto font-bold">{''}</div>
						<div className="mt-2 flex sm:mr-10">
							<button
								onClick={handlePreviousPage}
								disabled={currentPage === 1}
								className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
							>
								{t('previous')}
							</button>
							<div className="bg-[#099204]  w-[20px] h-[30px] flex items-center  justify-center">
								<span className="text-center text-white">
									{currentPage}
								</span>
							</div>
							<button
								onClick={handleNextPage}
								disabled={!hasMoreData}
								className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
							>
								{t('next')}
							</button>
						</div>
					</div>
				)}

				<div className="mr-10 mt-10 mb-10">
					<Table
						columns={columns}
						dataSource={fdata}
						pagination={false}
						bordered
						className="w-full"
						rowKey="zakaz"
						rowClassName="text-center"
						scroll={{ x: scrollX }}
						loading={loading}
						{...(handleRowClick && {
							onRow: (record) => ({
								onClick: () => handleRowClick(record),
							}),
						})}
						{...(isSticky && {
							sticky: false,
						})}
					/>
				</div>
			</div>
		</div>
	)
}
export default Header
