import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnProductionOrders } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewProductionOrderModal from '../../../components/Modal/viewProductionOrderModal'
import { useQuery } from '@tanstack/react-query'
import { fetchProductionOrders, fetchWarehouse } from '../../../utils/fetchData'
import { useSelector } from 'react-redux'
import { handleNavigateSeriesProdManager } from '../../../utils/navigateToPdf'

const ProductionOrders = () => {
	const { t } = useTranslation()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)
	const [pageSize] = useState('10')

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionOrders',
			currentPage,
			pageSize,
			startDate,
			endDate,
			roleNames.includes('admin') || roleNames.includes('ProdManager')
				? ''
				: getMe?.departmentName,
		],
		queryFn: fetchProductionOrders,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		console.log('record in ', record)
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const columns = columnProductionOrders(
		t,
		handleRowClick,
		handleNavigateSeriesProdManager,
	)

	return (
		<Layout>
			<Header
				title={'productionOrders'}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				isSticky={false}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
			/>
			<ViewProductionOrderModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					refetch()
					setIsModalVisible(false)
				}}
				refetch={refetch}
			/>
		</Layout>
	)
}

export default ProductionOrders
