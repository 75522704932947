import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnDebtors } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import { debouncedSet } from '../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import { fetchDebtors } from '../../../utils/fetchData'

const DebtorsAdmin = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0)
  const [cardName, setCardName] = useState('')

  const { data = [], isLoading } = useQuery({
    queryKey: ['debtors', currentPage, cardName],
    queryFn: fetchDebtors,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleCardNameChange = (val) => {
    debouncedSet(val, setCardName)
  }

  const handleSeeBtnClick = (cardCode) => {
    navigate(`/see-sales`)
    localStorage.setItem('cardCode', cardCode)
    console.log('localStorageCardCode', cardCode)
  }

  const columns = columnDebtors(t, handleCardNameChange, handleSeeBtnClick)

  return (
    <Layout>
      <Header
        title={'Accounts receivable'}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
      />
    </Layout>
  )
}

export default DebtorsAdmin
