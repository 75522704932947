import { Modal, Input, Table, Button } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { http } from '../../services/http'
import CreteLandedCostsModal from './createLandedCostsModal'
import SuccessModal from '../Modal/SuccessModal'
import ErrorModal from '../Modal/ErrorModal'
import moment from 'moment'
const ViewPurchaseIncomingModal = ({
  visible,
  data,
  onClose,
  onClick,
  finishSale = false,
  refetch,
}) => {
  const { t } = useTranslation()

  const successRef = useRef()
  const errorRef = useRef()

  const roleNames = useSelector((state) => state.main.roleNames)
  const employeeId = useSelector((state) => state.main.employeeId)
  const salesPersonCode = useSelector((state) => state.main.salesPersonCode)

  const [dueDate, setDueDate] = useState('')
  const [shipBtnLoading, setShipBtnLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dopZatrataModal, setDopZatrataModal] = useState(false)

  const handleButtonClick = () => {
    setIsModalVisible(true)
  }

  const handleModalYes = () => {
    setDopZatrataModal(true)
    setIsModalVisible(false)
  }

  const handleModalNo = () => {
    finishSalePost()
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (data?.docDueDate) {
      let date = ''
      ;(finishSale && roleNames.includes('Supplier')) ||
      roleNames.includes('admin')
        ? (date = moment().format('YYYY-MM-DD'))
        : (date = moment(data.docDueDate).format('YYYY-MM-DD'))
      setDueDate(date)
      console.log(date)
    }
    if (data?.u_Status) {
      setStatus(data.u_Status)
    }
  }, [data])

  const finishSalePost = async () => {
    setShipBtnLoading(true)

    const documentLines = data?.documentLines.map((item, idx) => {
      return {
        baseEntry: data.docEntry,
        baseLine: item.lineNum,
        itemCode: item.itemCode,
        quantity: item.quantity,
        price: item.price,
        currency: item.currency,
        warehouseCode: item.warehouseCode,
      }
    })

    const postingData = {
      cardCode: data?.cardCode,
      documentsOwner: employeeId,
      salesPersonCode: salesPersonCode,
      docDueDate: dueDate,
      docDate: dueDate,
      comments: data?.comments,
      docCurrency: data.documentLines[0].currency,
      u_FuraNum: data.u_FuraNum,
      u_InvoiceNum: data.docNum,
      documentLines,
    }

    try {
      await http.post('api/purchase/haridni-yakunlash', postingData)
      onClose()
      refetch()
      successRef.current?.open(t('sucCreation'))
    } catch (error) {
      console.log(error)
      errorRef.current?.open(error?.response?.data?.message)
    } finally {
      setShipBtnLoading(false)
    }
  }

  if (!data) return null

  const columns = [
    {
      title: t('item'),
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      width: 150,
      render: (text, record, index) => {
        return <span>{text}</span>
      },
    },

    {
      title: t('quantityN'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span> {formattedText}</span>
      },
    },
    {
      title: t('kv.m'),
      dataIndex: 'ugpCode',
      key: 'ugpCode',
    },

    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span>{formattedText} USD</span>
      },
    },

    {
      title: t('totalMiqdor'),
      dataIndex: 'lineTotal',
      key: 'lineTotal',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span>{formattedText} USD</span>
      },
    },
  ]

  const tableData = data?.documentLines.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    quantity: item.quantity,
    price: item.price,
    lineTotal: item.lineTotal,
    whsName: item.whsName,
    ugpCode: item.ugpCode,
  }))

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t('order')} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="90%"
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-bold">{t('mijoz')}</p>
              <Input
                type="text"
                value={data.cardName}
                className="flex  w-[200px] items-center justify-center sm:w-[250px]"
                style={{ color: 'black' }}
                disabled
              />
            </div>
            {data.u_Status ? (
              <div className="flex flex-col gap-3">
                <p className="font-bold">{t('status')}</p>
                <Input
                  value={t(`${status}`)}
                  style={{ color: 'black' }}
                  className="flex  w-[200px] items-center justify-center sm:w-[250px]"
                  disabled
                />
              </div>
            ) : (
              ''
            )}

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('currency')}</p>
              <Input
                value={data.documentLines[0].currency}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('date')}</p>
              <Input
                type="date"
                value={dueDate}
                style={{ color: 'black' }}
                onChange={(e) => {
                  console.log(e.target.value)
                  setDueDate(e.target.value)
                }}
                disabled={
                  finishSale && roleNames.includes('Supplier') ? false : true
                }
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: '100%' }}
            bordered
          />

          <div className="w-full flex items-center justify-between mt-20  ">
            <div className="flex items-center gap-3 ">
              <p className="font-bold">{t('totalForDocument')}:</p>
              <Input
                type="text"
                value={`${new Intl.NumberFormat('fr-FR').format(data.docTotal)} USD`}
                className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div>
              <>
                {roleNames.includes('Supplier') ||
                roleNames.includes('admin') ? (
                  <Button
                    onClick={handleButtonClick}
                    loading={shipBtnLoading}
                    className="h-[30px] bg-[#0A4D68] rounded-lg text-white"
                  >
                    {t('finishSale')}
                  </Button>
                ) : (
                  ''
                )}

                <Modal
                  open={isModalVisible}
                  onCancel={handleModalCancel}
                  footer={null}
                >
                  <div className="flex flex-col items-center justify-between m-5">
                    <p className="font-bold text-lg">
                      {t('haveYouGotDopZatrata')}
                    </p>

                    <div className="flex items-center gap-5 mt-5">
                      <Button
                        className="bg-red-600 text-white"
                        key="no"
                        onClick={handleModalNo}
                      >
                        {t('no')}
                      </Button>

                      <Button
                        key="yes"
                        className="bg-[#0A4D68] text-white"
                        onClick={handleModalYes}
                      >
                        {t('yes')}
                      </Button>
                    </div>
                  </div>
                </Modal>
              </>
            </div>
          </div>
        </div>
        <CreteLandedCostsModal
          visible={dopZatrataModal}
          data={data}
          docDate={dueDate}
          onClose2={() => {
            setDopZatrataModal(false)
            setTimeout(() => onClose(), 2000)
          }}
          refetch={() => refetch()}
        />
        <SuccessModal
          getRef={(r) => {
            successRef.current = r
          }}
        />
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r
          }}
        />
      </div>
    </Modal>
  )
}

export default ViewPurchaseIncomingModal
