import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../../components/header'
import { columnIncomingPayment } from '../../../../utils/columns'
import { getToday } from '../../../../utils/time'
import Layout from '../../../../components/Layout'
import ViewIncomingPaymentModal from '../../../../components/Modal/viewIncomingPaymentModal'
import CreateIncomingPaymentModal from './Create'
import { useQuery } from '@tanstack/react-query'
import {
	fetchIncomingPayments,
	fetchCashAccounts,
} from '../../../../utils/fetchData'
import { debouncedSet } from '../../../../utils/debounce'
import { useSelector } from 'react-redux'

const IncomingPayments = () => {
	const { t } = useTranslation()
	const formattedDate = getToday()
	const roleNames = useSelector((state) => state.main.roleNames)

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [addModalVisible, setIsAddModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [cardName, setCardName] = useState('')
	const [cash, setCash] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'incomingPayments',
			currentPage,
			cardName,
			docNum,
			startDate,
			endDate,
			cash,
		],
		queryFn: fetchIncomingPayments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: cashAccs = [] } = useQuery({
		queryKey: ['cashAccounts'],
		queryFn: fetchCashAccounts,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleAddClick = () => {
		setIsAddModalVisible(true)
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setCash)
	}

	const columns = columnIncomingPayment(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange,
		cashAccs,
	)

	return (
		<Layout>
			<Header
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
				isSticky={false}
				scrollX={1200}
				{...(roleNames.includes('cashier')
					? { onBtnClick: handleAddClick }
					: {})}
				{...(roleNames.includes('admin')
					? { title: 'incomingAdmin' }
					: { title: 'incomingPayment' })}
			/>
			<ViewIncomingPaymentModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				refetch={refetch}
			/>
			<CreateIncomingPaymentModal
				visible={addModalVisible}
				onClose={() => {
					setIsAddModalVisible(false)
					refetch()
				}}
			/>
		</Layout>
	)
}

export default IncomingPayments
