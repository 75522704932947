import { Modal, Input, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
const ViewDepartmentsBalanceModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation()

	if (!data) return null

	const columns = [
		{
			title: t('branch'),
			dataIndex: 'department',
			key: 'department',
			width: 150,
		},
		{
			title: t('debt2'),
			dataIndex: 'balance',
			key: 'balance',
			render: (text) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span>{formattedText} USD</span>
			},
		},
		{
			title: t('invoicesTotal'),
			dataIndex: 'invoicesTotal',
			key: 'invoicesTotal',
			render: (text) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span>{formattedText} USD</span>
			},
		},
		{
			title: t('incomingPaymentsTotal'),
			dataIndex: 'incomingPaymentsTotal',
			key: 'incomingPaymentsTotal',
			render: (text) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span>{formattedText} USD</span>
			},
		},
		{
			title: t('returnsTotal'),
			dataIndex: 'returnsTotal',
			key: 'returnsTotal',
			render: (text) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span>{formattedText} USD</span>
			},
		},
	]

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('bpDebt')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="70%"
			style={{ height: '80%' }}
		>
			<div className="w-full px-4 sm:px-5">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito font-semibold">
								{t('cardName')}
							</p>
							<Input
								type="text"
								value={data?.cardName}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: 'black' }}
								disabled
							/>
						</div>
					</div>

					<div className="mt-5 flex flex-col">
						<span className="text-sm text-[#000000]">
							{t('ifNegativeThisMeansNoDebt')}
						</span>
						<span className="text-sm text-[#000000]">
							{t('ifPositiveThisMeansDebt')}
						</span>
					</div>

					<Table
						columns={columns}
						dataSource={data?.departments}
						pagination={false}
						className="border-1 mt-5 border-black"
						scroll={{ x: '100%' }}
						bordered
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ViewDepartmentsBalanceModal
