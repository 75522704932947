import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnCashs } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import { useQuery } from '@tanstack/react-query'
import { fetchCashAccounts } from '../../../utils/fetchData'
import CreateCashModal from '../../../components/Modal/createCashModal'
import EditCashModal from '../../../components/Modal/editCashModal'
const Cashs = () => {
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [selectedViewRowData, setSelectedViewRowData] = useState(null)

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['cashAccounts'],
    queryFn: fetchCashAccounts,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }

  const handleViewRowClick = (record) => {
    setSelectedViewRowData(record)
    setIsViewModalVisible(true)
  }

  const columns = columnCashs(t, handleViewRowClick)

  return (
    <Layout>
      <Header
        title={'cashs'}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        onBtnClick={handleRowClick}
        isSticky={false}
      />

      <CreateCashModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          setIsModalVisible(false)
          refetch()
        }}
      />
      <EditCashModal
        visible={isViewModalVisible}
        data={selectedViewRowData}
        onClose={() => {
          setIsViewModalVisible(false)
          refetch()
        }}
      />
    </Layout>
  )
}

export default Cashs
