import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../../../../services/http'
import { useSelector } from 'react-redux'
import SuccessModal from '../../../../../components/Modal/SuccessModal'
import ErrorModal from '../../../../../components/Modal/ErrorModal'

const CreateSendMoneyModal = ({ visible, onClose }) => {
	const { t } = useTranslation()
	const { uzsCash, usdCash, department } = useSelector((state) => state.main)
	const successRef = useRef()
	const errorRef = useRef()

	const [loading, setLoading] = useState(false)
	const [currency, setCurrency] = useState('')
	const [comment, setComment] = useState('')
	const [amount, setAmount] = useState('')

	const postData = async () => {
		setLoading(true)
		const paymentAccounts = [
			{
				accountCode:
					currency === 'USD'
						? usdCash
						: currency === 'UZS'
							? uzsCash
							: '',
				sumPaid: Number(amount),
			},
		]
		try {
			await http.post('api/incomingpayments/send-money', {
				cardCode:
					currency === 'USD'
						? usdCash
						: currency === 'UZS'
							? uzsCash
							: '',
				actCurr: currency,
				cashSum: Number(amount),
				comments: comment,
				u_Department: department,
				paymentAccounts,
			})
			onClose()
			successRef.current.open(t('sucCreated'))
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('createSendMoney')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="65%"
			style={{ height: '70%' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center gap-10 justify-between mt-5">
					<div className="flex flex-col gap-3 w-full">
						<p className="font-bold">{t('currency')}</p>

						<Select
							type="text"
							onChange={(val) => {
								setCurrency(val)
							}}
							className="flex h-10 w-full items-center justify-center "
						>
							<Select.Option value={'USD'}>USD</Select.Option>
							<Select.Option value={'UZS'}>UZS</Select.Option>
						</Select>
					</div>
					<div className="flex flex-col items-start gap-3 w-full">
						<p className="font-bold">{t('sendingPrice')}</p>
						<Input
							type="text"
							onChange={(e) => {
								setAmount(e.target.value)
							}}
							value={amount}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 w-full mt-5">
					<p className="font-bold">{t('comments')}</p>
					<TextArea
						onChange={(e) => {
							setComment(e.target.value)
						}}
						className="w-full"
						rows={4}
					/>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('back')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('add')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default CreateSendMoneyModal
