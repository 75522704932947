import * as React from 'react'
const DocumentIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			stroke="white"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M4 6a4 4 0 0 1 4-4h7.343a4 4 0 0 1 2.829 1.172l2.656 2.656A4 4 0 0 1 22 8.657V18a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V6Z"
		/>
		<path
			stroke="white"
			strokeLinecap="round"
			strokeWidth={1.5}
			d="M9 7h8M9 12h8M9 17h4"
		/>
	</svg>
)
export default DocumentIcon
