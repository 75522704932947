import React, { useEffect, useState } from 'react'
import BarcodeGenerator from '../../../components/Barcode/index'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { clearOrderData } from '../../../slice/mainSlice'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const MarshrutPage = () => {
	const dispatch = useDispatch()
	const [data, setData] = useState(null)

	const { ordersPage } = useSelector((state) => state.main)

	useEffect(() => {
		setTimeout(() => {
			window.print()
		}, 1000)
	}, [])

	useEffect(() => {
		const storedData = sessionStorage.getItem('orderData')
		if (storedData) {
			setData(JSON.parse(storedData))
			console.log('data = ', JSON.parse(storedData))
		}
	}, [])

	useEffect(() => {
		console.log('ordersPage =', ordersPage)
		return () => {
			dispatch(clearOrderData())
		}
	}, [dispatch])

	return (
		<div className="p-2 bg-white text-black font-sans">
			<div className="border-b ">
				<div className="flex justify-between items-center">
					<div>
						<BarcodeGenerator docNum={data?.docNum} />
					</div>
					<div className="text-lg">
						<span className="font-bold">Менеджер:</span>{' '}
						{data?.slpName}
					</div>
				</div>

				<p className="font-bold text-lg text-center">
					ЗАКАЗ № {data?.docNum}
				</p>

				<div className="flex items-center justify-between  mb-5 text-lg ">
					<div className="mt-5 flex flex-col gap-3">
						<p className="font-bold">Информация о заказе</p>
						<p>
							<span className="font-bold">Дата:</span>{' '}
							{moment(data?.docDate).format('DD.MM.YYYY')}
						</p>
						<p>
							<span className="font-bold">Срок:</span>{' '}
							{moment(data?.docDueDate).format('DD.MM.YYYY')}
						</p>
					</div>

					<div className="text-right flex flex-col gap-3">
						<p className="mt-2 font-bold">
							Информация о покупателе
						</p>
						<p>
							<span className="font-bold">Ф.И.О.:</span>
							{data?.cardName}
						</p>
						{/* <p>
              <span className="font-bold">Тел.:</span> +998 (98) 809-07-76
            </p> */}
					</div>
					<div></div>
				</div>
			</div>

			<div className="mb-6 mt-5">
				<h2 className="text-center font-bold mb-2 text-lg">
					Позиции стекла
				</h2>
				<table className="w-full border-collapse border border-black text-sm">
					<thead>
						<tr className="print:bg-[#81857B] text-white font-bold text-[9px] bg-[#81857B]  print-bg-color">
							<th className="border border-black px-2 py-1">№</th>
							<th className="border border-black px-2 py-1">
								Товар
							</th>
							<th className="border border-black px-2 py-1">
								Услуга
							</th>
							<th className="border border-black px-2 py-1">
								Ширина
							</th>
							<th className="border border-black px-2 py-1">
								Высота
							</th>
							<th className="border border-black px-2 py-1">
								Кол-во
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.documentLines
							.filter((item, idx) => {
								return item.u_Produkt === null
							})
							.map((item, idx) => {
								return (
									<tr className=" font-bold text-[9px]   print-bg-color">
										<td className="border border-black  px-2 py-1 text-center">
											{idx + 1}
										</td>
										<td className="border border-black  px-2 py-1">
											{item.itemDescription}
										</td>
										<td className="border border-black  px-2 py-1">
											{item.u_Produkt || '-'}
										</td>
										<td className="border border-black  px-2 py-1 text-center">
											{item.u_Width || '-'}
										</td>
										<td className="border border-black  px-2 py-1 text-center">
											{item.u_Height || '-'}
										</td>
										<td className="border border-black  px-2 py-1 text-center">
											{item.u_PlannedQuantity || '-'}
										</td>
									</tr>
								)
							})}
					</tbody>
				</table>
			</div>

			<div className="mb-6 mt-5">
				<h2 className="text-center font-bold mb-2 text-lg">
					Маршрутный лист
				</h2>
				<table className="w-full border-collapse border border-black text-sm">
					<thead>
						<tr className="print:bg-[#81857B] text-white font-bold text-[9px] bg-[#81857B]  print-bg-color">
							<th className="border border-black px-2 py-1">№</th>
							<th className="border border-black px-2 py-1">
								Услуги
							</th>
							<th className="border border-black px-2 py-1">
								Кол-во
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.documentLines
							.filter((item, idx) => {
								return item.u_Produkt !== null
							})
							.map((item, idx) => {
								return (
									<tr className=" font-bold text-[9px]   print-bg-color">
										<td className="border border-black  px-2 py-1 text-center">
											{idx + 1}
										</td>
										<td className="border border-black  px-2 py-1">
											{item.itemDescription}
										</td>

										<td className="border border-black  px-2 py-1 text-center">
											{item.quantity || '-'}
										</td>
									</tr>
								)
							})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default MarshrutPage
