import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, Select, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import moment from 'moment'
import { useSelector } from 'react-redux'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
const OrderPaymentModal = ({ visible, onClose, data }) => {
	const { t } = useTranslation()

	const { uzsCash, department, usdCash, terminalCash, employeeId } =
		useSelector((state) => state.main)

	const successRef = useRef()
	const errorRef = useRef()

	const [loading, setLoading] = useState(false)
	const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))
	const [cashAccount, setCashAccount] = useState('')
	const [currency, setCurrency] = useState('')
	const [comment, setComment] = useState('')
	const [amount, setAmount] = useState(0)

	const postData = async () => {
		setLoading(true)
		const cash =
			cashAccount === 'Cash' && currency === 'USD'
				? usdCash
				: cashAccount === 'Cash' && currency === 'UZS'
					? uzsCash
					: cashAccount === 'Terminal'
						? terminalCash
						: ''
		try {
			const documentLines = data?.documentLines?.map((item, index) => ({
				lineNum: item.lineNum,
				itemCode: item.itemCode,
				quantity: item.quantity,
				unitPrice: item.price,
				currency: item.currency,
			}))

			const salesOrders = [
				{
					docEntry: data?.docEntry,
					cardCode: data?.cardCode,
					documentsOwner: employeeId,
					salesPersonCode: data?.salesPersonCode,
					sumApplied: Number(amount),
					documentLines: documentLines,
				},
			]
			await http.post('api/incomingpayments/orders', {
				docDate: moment(docDate).format('YYYY-MM-DD'),
				cashAccount: cash,
				docCurrency: currency,
				remarks: comment,
				cardCode: data?.cardCode,
				u_DdsArticle: 'Доход от реализации',
				u_Department: department,

				salesOrders,
			})
			successRef.current.open(t('sucCreated'))
			resetForm()
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	const resetForm = () => {
		setDocDate(moment().format('YYYY-MM-DD'))
		setCashAccount('')
		setCurrency('')
		setComment('')
		setAmount(0)
		onClose()
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('payTitle')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="55%"
			style={{ height: '70%' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3 ">
						<p className="font-bold">{t('currency')}</p>

						<Select
							type="text"
							onChange={(val) => {
								setCurrency(val)
							}}
							value={currency}
							className="flex h-10 w-[250px] items-center justify-center "
						>
							<Select.Option value="USD">USD</Select.Option>
							<Select.Option value="UZS">UZS</Select.Option>
						</Select>
					</div>
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('date')}</p>
						<div className="flex h-10 w-[130px] items-center justify-center border-[1px] border-[#D0D0D0]">
							<Typography>
								{moment(docDate).format('DD.MM.YYYY')}
							</Typography>
						</div>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className=" flex flex-col items-start gap-3  ">
						<p className="font-bold">{t('paymentType')}</p>
						<Select
							type="text"
							onChange={(val) => {
								setCashAccount(val)
							}}
							value={cashAccount}
							className="flex h-10 w-[250px] items-center justify-center"
						>
							<Select.Option value="Cash">
								{t('cash')}
							</Select.Option>
							<Select.Option value="Terminal">
								{t('terminal')}
							</Select.Option>
						</Select>
					</div>
					<div className="flex flex-col items-start gap-3 ">
						<p className="font-bold">{t('payingAmount')}</p>
						<Input
							type="text"
							onChange={(e) => {
								setAmount(e.target.value)
							}}
							value={amount}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 w-full mt-5">
					<p className="font-bold">{t('comments')}</p>
					<TextArea
						onChange={(e) => {
							setComment(e.target.value)
						}}
						value={comment}
						className="w-full"
						rows={4}
					/>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('back')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('pay')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
				onConfirm={() => window.location.reload()}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default OrderPaymentModal
