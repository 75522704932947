import moment from 'moment'

export const getToday = () => {
	return moment().format('YYYY-MM-DD')
}

export const getFiveDaysAgo = () => {
	return moment().subtract(5, 'days').format('YYYY-MM-DD')
}

export const calculateDurationString = (startTime, endTime) => {
	const start = moment(startTime, 'HH:mm:ss')
	const end = moment(endTime, 'HH:mm:ss')

	// Handle case where end time might be on the next day
	if (end.isBefore(start)) {
		end.add(1, 'day')
	}

	const duration = moment.duration(end.diff(start))
	const minutes = Math.floor(duration.asMinutes())
	const seconds = duration.seconds()

	// Format as "MM:SS"
	return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

export const formatTime = (seconds) => {
	const mins = Math.floor(Math.abs(seconds) / 60) * Math.sign(seconds) // Determine minutes with correct sign
	const secs = Math.abs(seconds) % 60 // Always take the absolute value of the remainder

	// Handle the formatting
	const sign = seconds < 0 ? '-' : '' // Determine overall sign
	const formattedMins = Math.abs(mins).toString().padStart(2, '0') // Absolute value of mins
	const formattedSecs = secs.toString().padStart(2, '0') // Always format seconds

	return `${sign}${formattedMins}:${formattedSecs}`
}

export const isSameDay = (dueDate, formattedDate = moment()) => {
	return moment(dueDate, 'DD.MM.YYYY').isSame(formattedDate, 'day')
}

export const isBefore = (dueDate, formattedDate = moment()) => {
	return moment(dueDate, 'DD.MM.YYYY').isBefore(formattedDate)
}

export const isAfter = (dueDate, formattedDate = moment()) => {
	return moment(dueDate, 'DD.MM.YYYY').isAfter(formattedDate)
}
