import OneSignal from 'react-onesignal'

export async function InitOneSignal() {
	OneSignal.init({
		appId: 'd93b9550-683a-4d2d-966e-0361a7ce696e',
	}).then(() => {
		OneSignal.setSubscription(true)
		console.log('OneSignal Initialized')

		// Add event listeners for notification events
		OneSignal.on('notificationDisplay', (event) => {
			console.log('Notification displayed:', event)
		})

		OneSignal.on('notificationDismiss', (event) => {
			console.log('Notification dismissed:', event)
		})
	})
}
