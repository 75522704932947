import React, { useState } from 'react'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnPurchaseOrder } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewPurchasesModal from '../../../components/Modal/viewPurchasesModal'
import { useQuery } from '@tanstack/react-query'
import { fetchPurchasesWithPageSize } from '../../../utils/fetchData'
import { useSelector } from 'react-redux'

const PurchaseInvoices = () => {
	const { t } = useTranslation()
	const formattedDate = getToday()
	const roleNames = useSelector((state) => state.main.roleNames)

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [cardName, setCardName] = useState('')
	const [slpName, setSlpName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)

	const { data = [], isLoading } = useQuery({
		queryKey: [
			'purchaseInvoice',
			currentPage,
			cardName,
			docNum,
			startDate,
			endDate,
			slpName,
			'api/purchase/tugallangan-haridlar',
		],
		queryFn: fetchPurchasesWithPageSize,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}
	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const debouncedSet = debounce((val, setState) => {
		setState(val)
	}, 500)

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName)
	}

	const columns = columnPurchaseOrder(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange,
		roleNames.includes('Supplier') ? true : false,
	)
	return (
		<Layout>
			<Header
				title={'purchase-invoices'}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
				isSticky={false}
			/>
			<ViewPurchasesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>
		</Layout>
	)
}

export default PurchaseInvoices
