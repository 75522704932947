import React, { useState, useRef, useEffect } from 'react'
import { Modal, Input, Button, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'

const CreateCashModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation()
  const successRef = useRef()
  const errorRef = useRef()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [code, setCode] = useState('50')
  const [currency, setCurrency] = useState('')
  const [biggerThan4, setBiggerThan4] = useState(false)


  const patchData = async () => {
    setLoading(true)

    try {
      await http.post('api/cashaccounts', {
        name,
        code,
        acctCurrency: currency,
      })
      successRef.current.open(t('sucCreated'))
      onClose()
      resetForm()
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setName(data?.description)
  }, [data])

  const resetForm = () => {
    setName('')
    setCode('')
    setCurrency('')
  }

  if (!data) return null

  return (
    <Modal
      title={
        <h1 className="font-nunito  font-extrabold text-[#000000]">
          {t('createBranch')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="50%"
      style={{ height: '70%' }}
    >
      <div className="w-full px-2 sm:px-14">
        <div className=" flex flex-col gap-3 sm:mt-5 w-full">
          <p>{`${t('currency')}`}</p>
          <Select
            type="text"
            value={currency}
            onChange={(val) => {
              setCurrency(val)
            }}
            className="flex h-10 w-1/2 items-center justify-center border-[1px] border-[#D0D0D0]"
            options={[
              {
                value: 'USD',
                label: 'USD',
              },
              {
                label: 'UZS',
                value: 'UZS',
              },
              {
                label: t('all'),
                value: '##',
              },
            ]}
          />
        </div>
        <div className="flex items-start gap-5 justify-between mt-4">
          <div className=" flex flex-col gap-3 sm:mt-5 w-full">
            <p>{`${t('code')}`}</p>
            <Input
              type="text"
              value={code}
              onChange={(e) => {
                const inputValue = e.target.value
                if (!inputValue.startsWith('5')) {
                  setCode('5')
                  setBiggerThan4(false)
                } else if (inputValue.length > 4) {
                  setBiggerThan4(true)
                } else {
                  setCode(inputValue)
                  setBiggerThan4(false)
                }
              }}
              className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
            />
            {biggerThan4 && (
              <span className="text-red-600 font-semibold">
                {t('cashCodeBiggerThan4')}
              </span>
            )}
          </div>
          <div className=" flex flex-col gap-3 sm:mt-5 w-full">
            <p>{`${t('name')}`}</p>
            <Input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>

        <div className="flex items-center gap-10 mt-14 justify-between">
          <Button
            onClick={() => {
              onClose()
            }}
            className="w-full bg-red-600 h-[35px] rounded-lg text-white"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={patchData}
            loading={loading}
            disabled={loading}
            className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
          >
            {t('add')}
          </Button>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default CreateCashModal
