import * as React from 'react'
const EndTimerIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			stroke="white"
			strokeLinecap="round"
			strokeWidth={1.5}
			d="M20.368 15A8.5 8.5 0 1 0 13 21.942M15 2.414C14.046 2.144 13.04 2 12 2s-2.046.144-3 .414"
		/>
		<path
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M20 17.757 15.757 22M20 22l-4.243-4.243"
		/>
		<path
			stroke="white"
			strokeWidth={1.5}
			d="M13.5 14a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
		/>
		<path
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M12 12V9"
		/>
	</svg>
)
export default EndTimerIcon
