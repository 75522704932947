import React, { memo, useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import ErrorImage from '../../../assets/images/error.png'
import ErrorModalStyle from './ErrorModalStyle'

const ErrorModalWithTimer = ({ getRef = () => {}, onConfirm = () => {} }) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [errorTitle, setErrorTitle] = useState('')
	const [time, setTime] = useState(5)

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				setErrorTitle(t)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [getRef])

	useEffect(() => {
		if (isOpenModal) {
			const timer = setInterval(() => {
				setTime(time - 1)
				if (time === 0) {
					setIsOpenModal(false)
					setTime(5)
				}
			}, 1000)
			return () => clearInterval(timer)
		}
	}, [isOpenModal, time])

	const formatTime = (seconds) => {
		const mins = Math.floor(Math.abs(seconds) / 60) * Math.sign(seconds) // Determine minutes with correct sign
		const secs = Math.abs(seconds) % 60 // Always take the absolute value of the remainder

		// Handle the formatting
		const sign = seconds < 0 ? '-' : '' // Determine overall sign
		const formattedMins = Math.abs(mins).toString().padStart(2, '0') // Absolute value of mins
		const formattedSecs = secs.toString().padStart(2, '0') // Always format seconds

		return `${sign}${formattedMins}:${formattedSecs}`
	}

	return (
		<Modal
			open={isOpenModal}
			onCancel={() => setIsOpenModal(false)}
			footer={null}
			centered
			closeIcon={<span style={{ fontSize: '1.5rem' }}>×</span>}
		>
			<ErrorModalStyle>
				<div className="card">
					<div className="flex justify-center">
						<img
							src={ErrorImage}
							alt="error image"
							className="img"
						/>
					</div>
					<h2 className="title">{errorTitle}</h2>
					{/* {time > 0 && ( */}
					<p className="text-gray-500 text-sm mt-2">
						Пожалуйста, подождите{' '}
						<span className="font-bold text-xl">
							{formatTime(time)} секунд
						</span>
						, чтобы закрыть окно
					</p>
					{/* )} */}
					<div className="centerCard">
						<Button
							type="primary"
							danger
							className="bg-red-600 w-[100px] text-sm h-12 text-white"
							onClick={() => {
								onConfirm()
								setIsOpenModal(false)
							}}
						>
							{'Понятно'}
						</Button>
					</div>
				</div>
			</ErrorModalStyle>
		</Modal>
	)
}

export default memo(ErrorModalWithTimer)
